import { Component, inject, OnInit } from '@angular/core'
import { Questionnaire, QuestionnaireItem } from '../models/fhir-interfaces'
import { v4 as uuidv4 } from 'uuid'
import { QuestionComponent } from '../question/question.component'
import { DividerComponent } from '@client-workspace/custom-components'
import { CommonModule } from '@angular/common'
import { BuilderFacade } from '../+state/builder.facade'
import { flatten } from '../../-shared/utils/utils'
import { EditorRendererComponent } from '../editor-renderer/editor-renderer.component'
import { HttpClient } from '@angular/common/http'
interface PaletteItem {
  type: string
  displayName: string
}

@Component({
  standalone: true,
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
  imports: [QuestionComponent, DividerComponent, CommonModule, EditorRendererComponent],
})
export class CanvasComponent {
  builderFacade = inject(BuilderFacade)
  properties: { name: string; title: string; id: string | undefined } = { name: '', title: '', id: undefined }

  questionnaire: QuestionnaireItem[] = []
  inhaltItems: PaletteItem[] = [
    { type: 'group', displayName: 'Gruppe' },
    { type: 'header', displayName: 'Überschrift' },
    { type: 'string', displayName: 'Text' },
  ]
  paletteItems: PaletteItem[] = [
    { type: 'input', displayName: 'Eingabefeld' },
    { type: 'boolean', displayName: 'Checkbox' },
    { type: 'date', displayName: 'Datum' },
    { type: 'choice', displayName: 'Dropdown' },
    { type: 'signature', displayName: 'Unterschrift' },
  ]

  constructor(protected httpClient: HttpClient) {
    this.builderFacade.questions$.subscribe((questions: QuestionnaireItem[]) => {
      this.questionnaire = questions
    })

    this.builderFacade.properties$.subscribe((properties) => {
      this.properties = properties
    })
  }

  dropHandler(event: any) {
    event.preventDefault()

    const targetEl = event.target
    // Remove is-hovering class
    this.removeHoveringClass(targetEl)
    const questions = structuredClone(this.questionnaire)

    const droppedItem = JSON.parse(event.dataTransfer.getData('item'))
    const newItem: QuestionnaireItem = {
      linkId: uuidv4(),
      text: `${droppedItem.displayName}`,
      type: droppedItem.type,
      required: false,
      answerOptions: droppedItem.type === 'choice' ? [] : undefined,
      enableWhen: [],
    }
    if (droppedItem.type === 'group') {
      newItem.layout = '100'
    }

    const parentObj = targetEl.closest('.group-container')
    const parentObjectId = parentObj?.id || null

    if (!parentObj) {
      questions.push(newItem)
    } else {
      const obj = flatten(questions).find((el) => el.linkId === parentObjectId)
      if (obj) {
        obj.items = obj.items || []

        obj.items.push(newItem)
      } else {
        questions.push(newItem)
      }
    }
    this.builderFacade.setQuestions(questions)
  }

  removeHoveringClass(targetEl: HTMLElement) {
    targetEl.classList.remove('is-hovering')
    targetEl.querySelectorAll('.group')?.forEach((el: Element) => {
      el.classList.remove('is-hovering')
    })
  }

  dragoverHandler(event: any) {
    event.preventDefault()
  }
  dragenterHandler(event: any) {
    event.preventDefault()
    event.target.classList.add('is-hovering')
  }
  dragleaveHandler(event: any) {
    event.preventDefault()
    event.srcElement.classList.remove('is-hovering')
  }

  dragstartHandler(event: any, item: any) {
    event.dataTransfer.setData('item', JSON.stringify(item))
    event.dataTransfer.dropEffect = 'copy'
  }

  onDelete(item: QuestionnaireItem) {
    const index: number = this.questionnaire.indexOf(item)
    if (index !== -1) {
      this.questionnaire.splice(index, 1)
    }
  }

  isVisible(item: QuestionnaireItem): boolean {
    if (!item.enableWhen || item.enableWhen.length === 0) {
      return true
    }

    return item.enableWhen.every((condition) => {
      // const control = this.form.get(condition.question)
      // if (!control) {
      //   return false
      // }
      // switch (condition.operator) {
      //   case '==':
      //     return control.value === condition.answerBoolean || control.value === condition.answerString
      //   case '!=':
      //     return control.value !== condition.answerBoolean && control.value !== condition.answerString
      //   default:
      //     return false
      // }
    })
  }

  async updateQuestionnaire() {
    if (this.properties.id) {
      this.builderFacade.updateQuestionnaire()
    }
  }

  async saveQuestionnaire() {
    this.builderFacade.saveQuestionnaire()
    // this.builderFacade.saveQuestionnaire(this.exportQuestionnaire())

    const all = await fetch('http://localhost:8080/services/crud-playground/questionnaires', {
      method: 'GET',
    })
    const data = await all.json()
    console.log(data)
  }
}
