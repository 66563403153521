import { FhirQuestionnaireResponse, FhirQuestionnaireResponseItem } from "./types/fhir.type";

export const extractPrepopulation = (response: FhirQuestionnaireResponse): Map<string, string | boolean> => {
  return extractSubItemsIfExist(response)
}

export const extractItemPrepopulation = (responseItem: FhirQuestionnaireResponseItem): Map<string, string | boolean> => {
  const result = extractSubItemsIfExist(responseItem)
  if (responseItem.answer) {
    for (const a of responseItem.answer) {
      if (a.valueBoolean !== undefined) result.set(responseItem.linkId, a.valueBoolean)
      else if (a.valueString) result.set(responseItem.linkId, a.valueString)
      else if (a.valueCoding) result.set(responseItem.linkId, `${a.valueCoding.system}:${a.valueCoding.code}`)
    }
  }
  return result
}

export const extractSubItemsIfExist = (responseItem: FhirQuestionnaireResponseItem | FhirQuestionnaireResponse) => {
  const result = new Map<string, string | boolean>()
  if (responseItem.item) {
    for (const i of responseItem.item) {
      for (const e of extractItemPrepopulation(i).entries()) {
        result.set(e[0], e[1])
      }
    }
  }
  return result
}
