<div [ngClass]="{ darken: requestSent }">
  <div class="inner-dialog">
    <div class="grid-full">
      <h2>
        <app-icon-edit-filled style="fill: var(--text-heading-primary)"></app-icon-edit-filled>
        {{ 'userManagement.editUserDialog.title' | translate }}
      </h2>
      <app-icon-close-filled
        style="fill: var(--text-heading-primary)"
        mat-dialog-close
        class="dialog-close-button"
      ></app-icon-close-filled>
      <p class="top-text">{{ 'userManagement.editUserForm.description' | translate }}</p>
    </div>

    <cc-input-field
      id="inputFirstName"
      label="{{ 'userManagement.formFieldLabel.firstName' | translate }}*"
      [(value)]="data.firstName"
      (valueChange)="firstNameInputIsDirty = true"
      subtext="{{ firstNameInputIsDirty && !isNameValid(data.firstName) ? ('userManagement.formField.firstNameRequiredHint' | translate) : '' }}"
      [status]="firstNameInputIsDirty && !isNameValid(data.firstName) ? 'error' : undefined"
    ></cc-input-field>

    <cc-input-field
      id="inputLastName"
      label="{{ 'userManagement.formField.lastNameLabel' | translate }}*"
      [(value)]="data.lastName"
      (valueChange)="lastNameInputIsDirty = true"
      subtext="{{ lastNameInputIsDirty && !isNameValid(data.lastName) ? ('userManagement.formField.lastNameRequiredHint' | translate) : '' }}"
      [status]="lastNameInputIsDirty && !isNameValid(data.lastName) ? 'error' : undefined"
    ></cc-input-field>

    <cc-input-field
      id="inputEmail"
      label="{{ 'formFieldLabel.email' | translate }}*"
      type="'email'"
      [(value)]="data.email"
      subtext="{{ wrongEMailPattern ? ('auth.notAValidEmailAddress' | translate) : '' }}"
      (valueChange)="checkEMailPattern()"
      [status]="wrongEMailPattern ? 'error' : undefined"
    ></cc-input-field>

    <!-- TODO: duplicate code, move to component -->
    <label for="role">{{ 'userManagement.inputField.roleLabel' | translate }}*</label>
    <br />
    <form class="user-form">
      <mat-form-field class="user-input">
        <mat-select
          [(value)]="data.roles"
          [formControl]="rolesFormControl"
          multiple
          placeholder="{{ 'userManagement.selectRoleLabel' | translate }}"
          id="role"
        >
          @for (role of roleList; track role) {
            <mat-option [value]="role">{{ i18nUtility.prefixRole(role) | translate }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </form>

    <!--TODO:
    <mat-checkbox [(ngModel)]="sendUpdate">{{ 'userManagement.editUserForm.sendUpdateEmail' | translate }}</mat-checkbox>
    -->
    <br />
    <label for="role" class="mandatory">*{{ 'userManagement.formField.mandatory' | translate }}</label>

    <button
      class="btn-primary width-100"
      (click)="updateUser()"
      [disabled]="
        !checkedEMailPattern ||
        !rolesFormControl.getRawValue()?.length ||
        requestSent ||
        !isNameValid(data.firstName) ||
        !isNameValid(data.lastName)
      "
    >
      {{ 'userManagement.editUserForm.saveLabel' | translate }}
    </button>
  </div>
</div>

@if (requestSent) {
  <mat-spinner></mat-spinner>
}
