import { Injectable } from '@angular/core'
import { Role } from '../../generated'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

@Injectable({
  providedIn: 'root',
})
export class I18nUtilityService {
  public static prefix(pre: string, term: string){
    const concatTerm = `${pre}${term}`
    _(concatTerm)
    return concatTerm;
  }

  public static prefixRole(role: Role){
    return I18nUtilityService.prefix(`userManagement.role.`, role)
  }
  public static prefixRoles(roles: Role[]){
    return roles.map(role => I18nUtilityService.prefix(`userManagement.role.`, role))
  }
}
