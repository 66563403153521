import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'

import * as AuthActions from './auth.actions'
import { AuthEntity } from './auth.models'
import { AuthenticatedUser, SessionMetaData } from '../../../generated'

export const AUTH_FEATURE_KEY = 'auth'

export interface AuthState extends EntityState<AuthEntity> {
  selectedId?: string | number // which Auth record has been selected
  loaded: boolean // has the Auth list been loaded
  error?: Error | null // last known error (if any)
  sessionMetaData: SessionMetaData | null
  currentUser: AuthenticatedUser | null
  passwordRequested: boolean
  passwordUpdated: boolean
  isTokenValid?: boolean
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState
}

export const authAdapter: EntityAdapter<AuthEntity> = createEntityAdapter<AuthEntity>()

export const initialAuthState: AuthState = authAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  sessionMetaData: null,
  currentUser: null,
  passwordRequested: false,
  passwordUpdated: false,
  isTokenValid: undefined,
})

const reducer = createReducer(
  initialAuthState,
  on(AuthActions.initAuth, (state) => ({ ...state, loaded: false, error: null })),
  on(AuthActions.loadAuthSuccess, (state, { auth }) => authAdapter.setAll(auth, { ...state, loaded: true })),
  on(AuthActions.loadAuthFailure, (state, { error }) => ({ ...state, error })),
  on(AuthActions.loginSuccess, (state, { auth }) => ({ ...state, sessionMetaData: auth })),
  on(AuthActions.refreshTokenSuccess, (state, { sessionMetaData }) => ({ ...state, sessionMetaData })),
  on(AuthActions.logoutSuccess, (state) => ({ ...state, user: null, sessionMetaData: null })),
  on(AuthActions.getAuthenticatedUserSuccess, (state, { user }) => ({ ...state, currentUser: user })),
  on(AuthActions.deleteSession, (state) => ({ ...state, currentUser: null, sessionMetaData: null })),
  on(AuthActions.forgotPasswordSuccess, (state) => ({ ...state, passwordRequested: true })),
  on(AuthActions.resetPasswordRequested, (state) => ({ ...state, passwordRequested: false })),
  on(AuthActions.resetPasswordSuccess, (state) => ({ ...state, passwordUpdated: true })),
  on(AuthActions.checkTokenValiditySuccess, (state) => ({ ...state, isTokenValid: true })),
  on(AuthActions.checkTokenValidityFailure, (state) => ({ ...state, isTokenValid: false })),
)

export function authReducer(state: AuthState | undefined, action: Action) {
  return reducer(state, action)
}
