import { Component } from '@angular/core'
import { ToastComponent, ToastType } from '../toast/toast.component'
import { ToastsService } from '../services/toasts.service'
import { trigger, transition, style, animate } from '@angular/animations'

export interface Toast {
  type: ToastType
  id?: string
  title?: string
  msg?: string
  label?: string
}

@Component({
  selector: 'lib-toasts',
  standalone: true,
  imports: [ToastComponent],
  templateUrl: './toasts.component.html',
  styleUrl: './toasts.component.scss',
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-10px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' }))]),
    ]),
  ],
})
export class ToastsComponent {
  toasts: Toast[] = []

  constructor(toastsService: ToastsService) {
    toastsService.itemAdded$.subscribe((item) => {
      if (typeof item.id !== 'string') {
        item.id = self.crypto.randomUUID()
      }
      this.toasts.unshift(item)
    })
  }

  removeToast(id: string) {
    this.toasts = this.toasts.filter((toast) => toast.id !== id)
  }
}
