import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  ButtonElementOption,
  DividerComponent,
  InputFieldComponent,
  ToggleButtonsComponent,
} from '@client-workspace/custom-components'
import { ActiveQuestionnaireItem } from '../../fhir-deserializer/types/active-questionnaire.type'
import { FhirAnswerOption } from '../../fhir-deserializer/types/fhir.type'

@Component({
  selector: 'fhir-questionnaire-item',
  standalone: true,
  imports: [CommonModule, DividerComponent, InputFieldComponent, ToggleButtonsComponent],
  templateUrl: './questionnaire-item.component.html',
  styleUrl: './questionnaire-item.component.scss',
})
export class QuestionnaireItemComponent implements OnInit {
  @Input() questionnaireItem!: ActiveQuestionnaireItem
  @Output() emitQuestionnaireItemChange = new EventEmitter()
  value = ''

  ngOnInit(): void {
    if (this.questionnaireItem) {
      this.value = this.questionnaireItem.answer?.getAnswer() as string
    }
  }

  setAnswerAndEmitChange(value: string | boolean) {
    this.questionnaireItem.setAnswer(value)
    this.emitQuestionnaireItemChange.emit()
  }

  createButtonElementsFromAnswerOptions(answerOptions: FhirAnswerOption[] | undefined) {
    return answerOptions
      ? (answerOptions?.map((answerOption) => ({
          label: answerOption.valueCoding?.display,
          value: `${answerOption.valueCoding?.system}:${answerOption.valueCoding?.code}`,
          checked: false,
        })) as ButtonElementOption[])
      : ([] as ButtonElementOption[])
  }

  createButtonElementsForBoolean = [
    {
      label: 'YES',
      value: 'true',
      disabled: false,
    },
    {
      label: 'NO',
      value: 'false',
      disabled: false,
    },
  ] as ButtonElementOption[]
}
