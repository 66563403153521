import { Component, inject, Input, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Questionnaire, QuestionnaireItem } from '../models/fhir-interfaces'
import { RouterLink } from '@angular/router'

@Component({
  selector: 'app-questionnaires-list',
  standalone: true,
  imports: [CommonModule, RouterLink],
  template: ` <section class="questionnaires-list">
    <button class="btn-primary small" [routerLink]="['/questionnaire-builder/new']">Create New Questionnaire</button>

    @for (item of items; track item.id) {
      <article class="item">
        <h2 class="name">{{ item.name }}</h2>
        <button class="btn-primary x-small" [routerLink]="['/questionnaire-builder/', item.id]">Edit</button>

        <button class="btn-warning x-small" (click)="deleteQuestionnaire(item.id!)">Delete</button>
      </article>
    }
  </section>`,
  styles: `
    .questionnaires-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-content: center;
      flex-wrap: wrap;
      padding-block: 3rem;
    }

    .item {
      padding: 1.25rem;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      width: 400px;
      background-color: var(--white-100);
      box-shadow: 0px 2px 16px 0px rgba(1, 52, 118, 0.16);
    }

    .name {
      margin-right: auto;
    }
  `,
})
export class QuestionnairesListComponent implements OnInit {
  items: Questionnaire[] = []

  ngOnInit(): void {
    this.loadQuestionnaires()
  }

  async loadQuestionnaires() {
    try {
      const data = await fetch(`http://localhost:8080/services/crud-playground/questionnaires`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      const questionnaires = await data.json()

      this.items = questionnaires.map((q: { id: string; body: any }) => {
        return { id: q.id, ...q.body }
      })
    } catch (error) {
      console.log(error)
    }
  }

  async deleteQuestionnaire(id: string) {
    const data = await fetch(`http://localhost:8080/services/crud-playground/questionnaires/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    if (data.status === 204) {
      this.loadQuestionnaires()
    }

    // if(data.status)
  }
}
