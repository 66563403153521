<div class="row">
  <div class="col-10 offset-1 col-md-6 offset-md-3 col-lg-6 offset-lg-3">
    @if (passwordUpdated$ | async) {
      <app-password-updated-screen [initial]="true"></app-password-updated-screen>
    } @else if ((isTokenValid$ | async) === false) {
      <app-token-expired-screen [showButton]="false"></app-token-expired-screen>
    } @else {
      <section id="initial-password" class="card">
        <h3 class="title heading-m">{{ 'auth.initialPassword.title' | translate }}</h3>

        <p class="m-bold">{{ 'auth.initialPassword.actionDescriptionPart1' | translate: { userMail: this.email } }}</p>
        <p class="text-m">{{ 'auth.initialPassword.actionDescriptionPart2' | translate }}</p>

        <app-password-guidelines
          (passwordOutput)="updatePasswordString($event)"
          (isValid)="checkPassword($event)"
          [email]="email"
        ></app-password-guidelines>

        <button [disabled]="!passwordIsValid" class="btn-send btn-primary width-100" (click)="handleSetPassword()">
          {{ 'auth.initialPassword.saveLabel' | translate }}
        </button>
      </section>
    }
  </div>
</div>
