<div class="error-container">
  <div class="error-message-container">
    <div class="heading-m m-semibold">{{ 'error.title' | translate }} {{ data.error.statusCode }}</div>
    <div class="heading-l font-semibold">{{ 'error.title'+data.error.statusCode  | translate }}</div>
    <div class="text-m">{{ data.error.message  | translate }}</div>
    @if (data.button) {
      <div>
        <button class=" btn-primary" [routerLink]="[data.button.link]">
          {{ data.button.label | translate }}
        </button>
      </div>
    }
  </div>
  <!-- TODO: input image? known images? -->
  <div class="error-image-container">
    <img ngSrc="../../../assets/img/not-found.png" class="error-image" alt="file-not-found-image" height="520"
         width="520" />
  </div>
</div>

