import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-circle-question-outline',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_923_20558)">
      <path
        d="M12 2C6.48603 2 2 6.48604 2 12C2 17.514 6.48603 22 12 22C17.514 22 22 17.514 22 12C22 6.48604 17.514 2 12 2ZM12 3.5C16.7033 3.5 20.5 7.2967 20.5 12C20.5 16.7033 16.7033 20.5 12 20.5C7.29669 20.5 3.5 16.7033 3.5 12C3.5 7.2967 7.29669 3.5 12 3.5ZM12 6.5C10.3521 6.5 9 7.85207 9 9.5V9.75C8.99859 9.84938 9.01696 9.94806 9.05402 10.0403C9.09108 10.1325 9.1461 10.2164 9.21588 10.2872C9.28566 10.358 9.36882 10.4142 9.46051 10.4526C9.5522 10.4909 9.65061 10.5107 9.75 10.5107C9.84939 10.5107 9.9478 10.4909 10.0395 10.4526C10.1312 10.4142 10.2143 10.358 10.2841 10.2872C10.3539 10.2164 10.4089 10.1325 10.446 10.0403C10.483 9.94806 10.5014 9.84938 10.5 9.75V9.5C10.5 8.66293 11.1629 8 12 8C12.8371 8 13.5 8.66293 13.5 9.5C13.5 10.6705 13.1957 10.8171 12.6621 11.2363C12.3953 11.4459 12.0459 11.6876 11.7471 12.0947C11.4483 12.5019 11.25 13.0694 11.25 13.75C11.2486 13.8494 11.267 13.9481 11.304 14.0403C11.3411 14.1325 11.3961 14.2164 11.4659 14.2872C11.5357 14.358 11.6188 14.4142 11.7105 14.4526C11.8022 14.4909 11.9006 14.5107 12 14.5107C12.0994 14.5107 12.1978 14.4909 12.2895 14.4526C12.3812 14.4142 12.4643 14.358 12.5341 14.2872C12.6039 14.2164 12.6589 14.1325 12.696 14.0403C12.733 13.9481 12.7514 13.8494 12.75 13.75C12.75 13.3234 12.833 13.1501 12.9561 12.9824C13.0791 12.8148 13.2922 12.6483 13.5879 12.416C14.1793 11.9513 15 11.0795 15 9.5C15 7.85207 13.6479 6.5 12 6.5ZM12 16C11.7348 16 11.4804 16.1054 11.2929 16.2929C11.1054 16.4804 11 16.7348 11 17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17C13 16.7348 12.8946 16.4804 12.7071 16.2929C12.5196 16.1054 12.2652 16 12 16Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_923_20558"><rect width="24" height="24" /></clipPath>
    </defs>
  </svg>`,
})
export class IconCircleQuestionOutlineComponent {}
