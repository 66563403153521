@if (questionnaireItem) {
  @switch (questionnaireItem.type) {
    @case ('group') {
      @for (questionnaireItem of questionnaireItem.item; track questionnaireItem.linkId; let i = $index) {
        @if (questionnaireItem.item) {
          <h6 class="heading-xs m-semibold">{{ questionnaireItem.text }}</h6>
        }
        <fhir-questionnaire-item
          [class]="'fhir-renderer_item-group-' + i"
          [class.fhir-renderer_item-group]="questionnaireItem.item"
          [class.fhir-renderer_itemless-group]="!questionnaireItem.item"
          [questionnaireItem]="questionnaireItem"
          (emitQuestionnaireItemChange)="emitQuestionnaireItemChange.emit()"
        />
      }
    }
    @case ('string') {
      <cc-input-field
        class="fhir-renderer_item"
        [label]="questionnaireItem.text"
        [(value)]="value"
        (inputValueChange)="setAnswerAndEmitChange(value)"
      ></cc-input-field>
    }
    @case ('boolean') {
      <cc-toggle-buttons
        class="fhir-renderer_item"
        [label]="questionnaireItem.text"
        [buttonElements]="createButtonElementsForBoolean"
        (valueChange)="setAnswerAndEmitChange($event)"
      />
    }
    @case ('choice') {
      <cc-toggle-buttons
        class="fhir-renderer_item"
        [label]="questionnaireItem.text"
        [buttonElements]="createButtonElementsFromAnswerOptions(questionnaireItem.answerOption)"
        (valueChange)="setAnswerAndEmitChange($event)"
      />
    }
    @default {
      {{ questionnaireItem.text }}
    }
  }
}
