import { inject, Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'

import * as AuthActions from './auth.actions'
import * as AuthSelectors from './auth.selectors'
import { Observable } from 'rxjs'
import { ResolveUpdatePasswordAction, TokenCredentials } from '../../../generated'

@Injectable()
export class AuthFacade {
  private readonly store = inject(Store)

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(AuthSelectors.selectAuthLoaded))
  allAuth$ = this.store.pipe(select(AuthSelectors.selectAllAuth))
  sessionMetaData$ = this.store.pipe(select(AuthSelectors.selectSessionMetaData))
  user$ = this.store.pipe(select(AuthSelectors.selectCurrentUser))
  session$ = this.store.pipe(select(AuthSelectors.selectSession))
  // if you need it often, you can do it like this
  hasPermissionCrudUser$ = this.hasPermission('crud-user')
  isPasswordRequested$ = this.store.pipe(select(AuthSelectors.isPasswordRequested))
  isPasswordUpdated$ = this.store.pipe(select(AuthSelectors.isPasswordUpdated))
  isTokenValid$ = this.store.pipe(select(AuthSelectors.isTokenValid))

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(AuthActions.initAuth())
  }

  login(data: { user: string; password: string; realm: string }) {
    this.store.dispatch(AuthActions.login(data))
  }

  logout() {
    this.store.dispatch(AuthActions.logout())
  }

  refreshToken() {
    this.store.dispatch(AuthActions.refreshToken())
  }

  hasPermission(permission: string): Observable<boolean | undefined> {
    return this.store.pipe(select(AuthSelectors.selectHasPermission(permission)))
  }

  forgotPassword(email: string, realm: string) {
    this.store.dispatch(AuthActions.forgotPassword({ email, realm }))
  }
  resetPassword(resolveUpdatePasswordAction: ResolveUpdatePasswordAction) {
    this.store.dispatch(AuthActions.resetPassword(resolveUpdatePasswordAction))
  }

  checkTokenValidity(tokenCredentials: TokenCredentials) {
    this.store.dispatch(AuthActions.checkTokenValidity(tokenCredentials))
  }

  resetPasswordRequested() {
    this.store.dispatch(AuthActions.resetPasswordRequested())
  }
}
