import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CanvasComponent } from './canvas/canvas.component'
import { PropertiesEditorComponent } from './properties-editor/properties-editor.component'
import { BuilderFacade } from './+state/builder.facade'

@Component({
  selector: 'app-questionnaire-builder',
  standalone: true,
  imports: [CommonModule, CanvasComponent, PropertiesEditorComponent],
  templateUrl: './questionnaire-builder.component.html',
  styleUrl: './questionnaire-builder.component.scss',
})
export class QuestionnaireBuilderComponent implements OnInit, OnDestroy {
  @Input() id!: string
  builderFacade = inject(BuilderFacade)

  ngOnInit(): void {
    this.loadQuestionnaire(this.id)
  }

  async loadQuestionnaire(id: string) {
    try {
      const data = await fetch(`http://localhost:8080/services/crud-playground/questionnaires/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      const questionnaire = await data.json()
      if (questionnaire.body) {
        this.builderFacade.loadQuestionnaire(questionnaire.id, questionnaire.body)
      }
    } catch (error) {
      console.log(error)
    }
  }

  ngOnDestroy(): void {
    this.builderFacade.resetState()
  }
}
