import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-message-typing-outline',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.25 3.5C3.46403 3.5 2 4.96403 2 6.75V15.25C2 17.036 3.46403 18.5 5.25 18.5H6V21.25C6 22.233 7.21365 22.8395 8 22.25L13 18.5H18.75C20.536 18.5 22 17.036 22 15.25V6.75C22 4.96403 20.536 3.5 18.75 3.5H5.25ZM5.25 5H18.75C19.725 5 20.5 5.77497 20.5 6.75V15.25C20.5 16.225 19.725 17 18.75 17H12.75C12.5876 17.0001 12.4296 17.0528 12.2998 17.1504L7.5 20.75V17.75C7.49998 17.5511 7.42096 17.3603 7.28031 17.2197C7.13966 17.079 6.94891 17 6.75 17H5.25C4.27497 17 3.5 16.225 3.5 15.25V6.75C3.5 5.77497 4.27497 5 5.25 5ZM8.5 10C7.9475 10 7.5 10.4475 7.5 11C7.5 11.5525 7.9475 12 8.5 12C9.0525 12 9.5 11.5525 9.5 11C9.5 10.4475 9.0525 10 8.5 10ZM12 10C11.4475 10 11 10.4475 11 11C11 11.5525 11.4475 12 12 12C12.5525 12 13 11.5525 13 11C13 10.4475 12.5525 10 12 10ZM15.5 10C14.9475 10 14.5 10.4475 14.5 11C14.5 11.5525 14.9475 12 15.5 12C16.0525 12 16.5 11.5525 16.5 11C16.5 10.4475 16.0525 10 15.5 10Z"
    />
  </svg>`,
})
export class IconMessageTypingOutlineComponent {}
