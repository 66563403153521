import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-edit-outline',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 2.50488C17.1027 2.50488 16.2054 2.84507 15.5254 3.52539L4.45804 14.5918C4.24805 14.8018 4.09426 15.0629 4.01272 15.3496L2.52932 20.5439C2.49274 20.6725 2.49116 20.8084 2.52475 20.9378C2.55833 21.0671 2.62587 21.1852 2.72036 21.2797C2.81486 21.3742 2.93288 21.4417 3.06223 21.4753C3.19158 21.5089 3.32755 21.5073 3.45608 21.4707L8.65139 19.9873L8.65237 19.9863C8.93713 19.9045 9.19763 19.7526 9.40823 19.542L20.4746 8.47461C21.8352 7.11405 21.8352 4.88595 20.4746 3.52539C19.7946 2.84507 18.8974 2.50488 18 2.50488ZM18 3.99609C18.5104 3.99609 19.0201 4.19276 19.4131 4.58594H19.4141C20.2015 5.37337 20.2015 6.62663 19.4141 7.41406L18.4444 8.38379L15.6162 5.55566L16.586 4.58594C16.979 4.19276 17.4896 3.99609 18 3.99609ZM14.5557 6.61621L17.3838 9.44434L8.34671 18.4814C8.31739 18.5106 8.28026 18.5329 8.23831 18.5449L4.34182 19.6582L5.45511 15.7607V15.7598C5.46657 15.7195 5.48757 15.6834 5.51858 15.6523L14.5557 6.61621Z"
    />
  </svg>`,
})
export class IconEditOutlineComponent {}
