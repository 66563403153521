import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-close-outline',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_932_20575)">
      <path
        d="M19.7432 3.48925C19.5451 3.49393 19.3569 3.57678 19.2197 3.71972L12 10.9394L4.78027 3.71972C4.71036 3.64773 4.62672 3.5905 4.53429 3.55141C4.44186 3.51233 4.34253 3.49219 4.24218 3.49218C4.09292 3.49222 3.94707 3.53679 3.82328 3.62018C3.69949 3.70358 3.60339 3.82201 3.54729 3.96033C3.49119 4.09864 3.47763 4.25055 3.50835 4.39662C3.53906 4.54269 3.61266 4.67627 3.71972 4.78027L10.9394 12L3.71972 19.2197C3.64774 19.2888 3.59028 19.3716 3.55069 19.4632C3.5111 19.5548 3.49019 19.6534 3.48917 19.7532C3.48816 19.8529 3.50706 19.9519 3.54478 20.0443C3.5825 20.1367 3.63827 20.2206 3.70883 20.2912C3.77939 20.3617 3.86331 20.4175 3.9557 20.4552C4.04808 20.4929 4.14706 20.5118 4.24684 20.5108C4.34662 20.5098 4.44519 20.4889 4.53679 20.4493C4.62838 20.4097 4.71116 20.3522 4.78027 20.2803L12 13.0605L19.2197 20.2803C19.2888 20.3522 19.3716 20.4097 19.4632 20.4493C19.5548 20.4889 19.6534 20.5098 19.7532 20.5108C19.8529 20.5118 19.9519 20.4929 20.0443 20.4552C20.1367 20.4175 20.2206 20.3617 20.2912 20.2912C20.3617 20.2206 20.4175 20.1367 20.4552 20.0443C20.4929 19.9519 20.5118 19.8529 20.5108 19.7532C20.5098 19.6534 20.4889 19.5548 20.4493 19.4632C20.4097 19.3716 20.3522 19.2888 20.2803 19.2197L13.0605 12L20.2803 4.78027C20.3893 4.67559 20.4642 4.5404 20.4951 4.39242C20.526 4.24444 20.5115 4.09057 20.4534 3.951C20.3953 3.81143 20.2965 3.69266 20.1697 3.61026C20.043 3.52786 19.8943 3.48568 19.7432 3.48925Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_932_20575"><rect width="24" height="24" /></clipPath>
    </defs>
  </svg>`,
})
export class IconCloseOutlineComponent {}
