import { Component, inject } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { InputFieldComponent } from '@client-workspace/custom-components'
import { IconCircleCheckFilledComponent } from '@client-workspace/icons/circle_check_filled.component'
import { getRealmNameFromUrl } from '../../../-shared/utils/utils'
import { EMAIL_REGEX } from '../../../-shared/utils/validation-regex.const'
import { AuthFacade } from '../+state/auth.facade'
import { AsyncPipe } from '@angular/common'

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [TranslateModule, InputFieldComponent, IconCircleCheckFilledComponent, AsyncPipe],
  template: `
    <div class="row">
      <div class="col-10 offset-1 col-md-6 offset-md-3 col-lg-6 offset-lg-3">
        <section class="card">
          @if (isPasswordRequested$ | async) {
            <div class="password-requested-wrapper">
              <header class=" m-text-center">
                <app-icon-circle-check-filled class="icon m-centered"></app-icon-circle-check-filled>
                <h3 class="heading-m m-semibold">{{ 'auth.forgotPassword.almostReadyMessage' | translate }}</h3>
              </header>
              <p class="text-m">{{ 'auth.forgotPassword.textPart1' | translate: { email } }}</p>
              <p class="text-m">{{ 'auth.forgotPassword.textPart2' | translate }}</p>
              <p class="text-m">{{ 'auth.forgotPassword.textPart3' | translate }}</p>
            </div>
          } @else {
            <h3 class="title heading-m m-semibold">{{ 'auth.forgotPassword.title' | translate }}</h3>

            <p class="text-m">{{ 'auth.forgotPassword.hint' | translate }}</p>
            <cc-input-field
              label="{{ 'formFieldLabel.email' | translate }}"
              placeholder="{{ 'formFieldLabel.email' | translate }}"
              [(value)]="email"
              subtext="{{ isWrongEmailPattern ? ('auth.notAValidEmailAddress' | translate) : '' }}"
              (valueChange)="checkEMailPattern()"
              [status]="isWrongEmailPattern ? 'error' : undefined"
            ></cc-input-field>

            <button
              [disabled]="!email || isWrongEmailPattern"
              class="btn-send btn-primary width-100"
              (click)="handleBtn()"
            >
              {{ 'auth.forgotPassword.button' | translate }}
            </button>
          }
        </section>
      </div>
    </div>
  `,
  styles: `
    .card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      margin-top: 60px;
      max-width: 480px;
      margin-inline: auto;
    }

    cc-input-field {
      width: 100%;
    }

    .password-requested-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .icon {
      fill: var(--color-green-700);
      zoom: 2.33;
      margin-bottom: 4px;
      display: block;
    }
  `,
})
export class ForgotPasswordComponent {
  email = ''
  isWrongEmailPattern = false
  auth = inject(AuthFacade)
  isPasswordRequested$ = this.auth.isPasswordRequested$

  constructor() {
    this.auth.resetPasswordRequested()
  }

  handleBtn() {
    this.checkEMailPattern()
    if (this.isWrongEmailPattern) {
      return
    }
    const realm = getRealmNameFromUrl(window.location.href)
    this.auth.forgotPassword(this.email, realm)
  }

  public checkEMailPattern(): void {
    this.isWrongEmailPattern = !EMAIL_REGEX.test(this.email)
  }
}
