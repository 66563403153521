import { Component, inject } from '@angular/core'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatSelectModule } from '@angular/material/select'
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { IconCloseFilledComponent } from '@client-workspace/icons/close_filled.component'
import { CommonModule } from '@angular/common'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { IconEditFilledComponent } from '@client-workspace/icons/edit_filled.component'
import { ToastsService } from '@client-workspace/toasts'
import { InputFieldComponent } from '@client-workspace/custom-components'
import { EMAIL_REGEX } from '../../../../-shared/utils/validation-regex.const'
import { Role, User } from '../../../../generated'
import { I18nUtilityService } from '../../../../-shared/services/i18n.utility-service'

@Component({
  selector: 'app-edit-user-form',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogClose,
    MatDialogModule,
    IconCloseFilledComponent,
    IconEditFilledComponent,
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatCheckboxModule,
    InputFieldComponent,
  ],
  templateUrl: './edit-user-form.component.html',
  styleUrl: './edit-user-form.component.scss',
})
export class EditUserFormComponent {
  data: User = { ...inject(MAT_DIALOG_DATA) }
  i18nUtility = I18nUtilityService
  rolesFormControl = new FormControl(this.data.roles)
  roleList = Object.values(Role)

  checkedEMailPattern = true
  wrongEMailPattern = false
  sendUpdate = false
  requestSent = false
  emailRegex = EMAIL_REGEX

  public firstNameInputIsDirty = false
  public lastNameInputIsDirty = false

  constructor(
    private dialog: MatDialogRef<EditUserFormComponent>,
    private toastsService: ToastsService,
    private translation: TranslateService,
  ) {}

  checkEMailPattern(): void {
    if (this.emailRegex.test(this.data.email)) {
      this.checkedEMailPattern = true
      this.wrongEMailPattern = false
    } else {
      this.checkedEMailPattern = false
      this.wrongEMailPattern = true
    }
  }

  updateUser() {
    this.dialog.close(this.data)
  }

  public isNameValid(name: string) {
    return name.length > 0 && name.length < 256
  }
}
