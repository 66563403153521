import { Component, inject } from '@angular/core'
import { NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { HeaderComponent } from './-shared/layout/header/header.component'
import { IconHomeOutlineComponent } from '@client-workspace/icons/home_outline.component'
import { IconUserOutlineComponent } from '@client-workspace/icons/user_outline.component'
import { IconOrganisationOutlineComponent } from '@client-workspace/icons/organisation_outline.component'
import { IconTreatmentOutlineComponent } from '@client-workspace/icons/treatment_outline.component'
import { ToastsComponent } from '@client-workspace/toasts'
import { Title } from '@angular/platform-browser'
import { IconContactOutlineComponent } from '@client-workspace/icons/contact_outline.component'
import { Breakpoint } from './+state/app.models'
import { debounce, fromEvent, map, timer } from 'rxjs'
import { AuthFacade } from './components/auth/+state/auth.facade'
import { AsyncPipe } from '@angular/common'
import { AppFacade } from './+state/app.facade'
import { HasPermissionDirective } from './-shared/directives/has-permission.directive'
import { IconMessageTypingOutlineComponent } from '@client-workspace/icons/message_typing_outline.component'

export const publicRoutes = ['/reset-password', '/forgot-password', '/initial-password', '/error', '/verify-email']

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    RouterOutlet,
    HeaderComponent,
    TranslateModule,
    RouterLink,
    RouterLinkActive,
    IconHomeOutlineComponent,
    IconUserOutlineComponent,
    IconOrganisationOutlineComponent,
    ToastsComponent,
    IconContactOutlineComponent,
    AsyncPipe,
    HasPermissionDirective,
    IconMessageTypingOutlineComponent,
    IconTreatmentOutlineComponent,
  ],
  providers: [Title],
})
export class AppComponent {
  app = inject(AppFacade)
  auth = inject(AuthFacade)
  user$ = this.auth.user$
  ready$ = this.app.ready$
  currentBreakpoint = ''
  isQuestionnaireVisible = false

  constructor(private readonly router: Router) {
    this.router.events.subscribe((val) => {
      if (
        val instanceof NavigationEnd &&
        ![...publicRoutes, '/login'].some((url) => val.url.includes(url)) &&
        val.url !== '/'
      ) {
        window.sessionStorage.setItem('current-page', val.url) // save it in case of login
      }

      if (window.sessionStorage.getItem('is-editor-visible')) {
        this.isQuestionnaireVisible = true
      }
    })

    const bps: { [key: string]: number } = { sm: 576, md: 768, lg: 992, xl: 1200 }

    fromEvent(window, 'resize')
      .pipe(
        debounce(() => timer(300)),
        map(() => window.innerWidth),
      )
      .subscribe((width) => {
        const newBreakpoint = Object.keys(bps).find((key) => width <= bps[key]) ?? 'xxl'
        if (this.currentBreakpoint !== newBreakpoint) {
          this.currentBreakpoint = newBreakpoint
          this.app.breakpointChange(newBreakpoint as Breakpoint)
        }
      })

    window.dispatchEvent(new Event('resize'))
  }
}
