import { Component, inject, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ActivatedRoute, RouterLink } from '@angular/router'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { TokenExpiredScreenComponent } from '../reset-password/token-expired-screen.component'
import { TranslateModule } from '@ngx-translate/core'
import { IconCircleCheckFilledComponent } from '@client-workspace/icons/circle_check_filled.component'
import { AuthFacade } from '../+state/auth.facade'

@Component({
  selector: 'app-verify-email',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    TokenExpiredScreenComponent,
    TranslateModule,
    IconCircleCheckFilledComponent,
    RouterLink,
  ],
  templateUrl: './verify-email.component.html',
  styleUrl: './verify-email.component.scss',
})
export class VerifyEmailComponent implements OnInit {
  token = ''
  email = ''
  auth = inject(AuthFacade)
  isTokenValid$ = this.auth.isTokenValid$

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.email = params['email']
      this.token = params['token']
    })
    this.auth.checkTokenValidity({ email: this.email, token: this.token })
  }
}
