import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-user-outline',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_964_20772)">
      <path
        d="M12 2C9.24746 2 7 4.24746 7 7C7 9.75254 9.24746 12 12 12C14.7525 12 17 9.75254 17 7C17 4.24746 14.7525 2 12 2ZM12 3.5C13.9419 3.5 15.5 5.05812 15.5 7C15.5 8.94188 13.9419 10.5 12 10.5C10.0581 10.5 8.5 8.94188 8.5 7C8.5 5.05812 10.0581 3.5 12 3.5ZM6.25 14C5.01625 14 4 15.0162 4 16.25V16.8496C4 18.32 4.93236 19.639 6.35449 20.5459C7.77663 21.4528 9.72242 22 12 22C14.2776 22 16.2234 21.4528 17.6455 20.5459C19.0676 19.639 20 18.32 20 16.8496V16.25C20 15.0162 18.9838 14 17.75 14H6.25ZM6.25 15.5H17.75C18.1732 15.5 18.5 15.8268 18.5 16.25V16.8496C18.5 17.6822 17.9637 18.5639 16.8389 19.2812C15.714 19.9986 14.0344 20.5 12 20.5C9.96558 20.5 8.286 19.9986 7.16113 19.2812C6.03627 18.5639 5.5 17.6822 5.5 16.8496V16.25C5.5 15.8268 5.82675 15.5 6.25 15.5Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_964_20772"><rect width="24" height="24" /></clipPath>
    </defs>
  </svg>`,
})
export class IconUserOutlineComponent {}
