import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'

import * as AdmissionsActions from './admissions.actions'
import { AdmissionsEntity } from './admissions.models'

export const ADMISSIONS_FEATURE_KEY = 'admissions'

export interface AdmissionsState extends EntityState<AdmissionsEntity> {
  selectedId?: string | number // which Admissions record has been selected
  loaded: boolean // has the Admissions list been loaded
  error?: string | null | Error // last known error (if any)
  admission?: AdmissionsEntity
  admissions: AdmissionsEntity[],
}

export interface AdmissionPartialState {
  readonly [ADMISSIONS_FEATURE_KEY]: AdmissionsState
}

export const admissionsAdapter: EntityAdapter<AdmissionsEntity> = createEntityAdapter<AdmissionsEntity>()

export const initialAdmissionsState: AdmissionsState = admissionsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  admissions: []
})

const reducer = createReducer(
  initialAdmissionsState,
  on(AdmissionsActions.initAdmissions, (state) => ({ ...state, loaded: false, error: null })),
  on(AdmissionsActions.loadAdmissionsSuccess, (state, { admissions }) =>
    admissionsAdapter.setAll(admissions, { ...state, loaded: true })
  ),
  on(AdmissionsActions.loadAdmissionsFailure, (state, { error }) => ({ ...state, error })),
  on(AdmissionsActions.createAdmissionsSuccess, (state, { admission }) => (
    admissionsAdapter.addOne(admission, { ...state, loaded: true }))
  ),
  on(AdmissionsActions.updateAdmission, (state, { id, updatedQuestionnaireResponse }) => (
    admissionsAdapter.updateOne({ id, changes: { questionnaireResponse: updatedQuestionnaireResponse } }, state)
  ))
)

export function admissionsReducer(state: AdmissionsState | undefined, action: Action) {
  return reducer(state, action)
}
