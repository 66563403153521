import { type Routes } from '@angular/router'
import { LoginComponent } from './login/login.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { InitialPasswordComponent } from './initial-password/initial-password.component'
import { VerifyEmailComponent } from './verify-email/verify-email.component'
import { LoginGuard } from '../../-shared/guards/login.guard'
/*
  IMPORTANT: App base is set in appConfig via APP_BASE_HREF and it should be /realms/{realmName}
  All routes are sub-pages of that base url, i.e. /home is basically realm/realm-name/home
*/
export const authRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
    title: 'CaaS - Login'
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    title: 'CaaS - Reset password'
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    title: 'CaaS - Forgot password'
  },
  {
    path: 'initial-password',
    component: InitialPasswordComponent,
    title: 'CaaS - Set initial password'
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
    title: 'CaaS - Verify email'
  }
]
