import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-settings-filled',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5695 13.141C19.213 12.8795 19 12.4595 19 12.017C19 11.5745 19.213 11.1545 19.569 10.8935L21.216 9.686C21.4785 9.493 21.587 9.1535 21.4845 8.844C21.0605 7.57 20.39 6.408 19.491 5.3895C19.2745 5.1455 18.9255 5.0685 18.627 5.1995L16.7725 6.015C16.3685 6.193 15.898 6.1675 15.5145 5.946C15.1315 5.725 14.8745 5.331 14.826 4.8915L14.603 2.8555C14.5675 2.5315 14.3265 2.2675 14.0075 2.202C12.709 1.9365 11.3445 1.932 10.023 2.197C9.702 2.2615 9.4605 2.5255 9.425 2.851L9.204 4.874C9.1555 5.314 8.8985 5.708 8.5145 5.929C8.1315 6.15 7.6625 6.1765 7.257 5.998L5.3925 5.178C5.0965 5.047 4.7465 5.123 4.53 5.3665C3.628 6.381 2.9545 7.5415 2.526 8.8145C2.422 9.1235 2.53 9.465 2.7935 9.6585L4.43 10.8585C4.787 11.1205 5 11.5405 5 11.983C5 12.4255 4.787 12.8455 4.431 13.1065L2.784 14.314C2.5215 14.507 2.413 14.8465 2.5155 15.156C2.9395 16.43 3.61 17.592 4.509 18.6105C4.7255 18.855 5.0755 18.9325 5.373 18.8005L7.2275 17.985C7.6315 17.807 8.1015 17.8325 8.4855 18.054C8.8685 18.275 9.1255 18.669 9.174 19.1085L9.397 21.1445C9.4325 21.4685 9.6735 21.7325 9.9925 21.798C10.6495 21.932 11.3245 22 12 22C12.659 22 13.324 21.9335 13.9765 21.8025C14.2975 21.738 14.539 21.474 14.5745 21.1485L14.796 19.1255C14.8445 18.6855 15.1015 18.2915 15.4855 18.0705C15.8685 17.85 16.338 17.824 16.743 18.0015L18.6075 18.8215C18.9045 18.953 19.2535 18.877 19.47 18.633C20.372 17.6185 21.0455 16.458 21.474 15.185C21.578 14.876 21.47 14.5345 21.2065 14.341L19.5695 13.141ZM12 15.5C10.067 15.5 8.5 13.933 8.5 12C8.5 10.067 10.067 8.5 12 8.5C13.933 8.5 15.5 10.067 15.5 12C15.5 13.933 13.933 15.5 12 15.5Z"
    />
  </svg>`,
})
export class IconSettingsFilledComponent {}
