import { Component, ElementRef, inject, OnInit, viewChild } from '@angular/core'
import { MatSortModule, Sort } from '@angular/material/sort'
import { AsyncPipe, DatePipe } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { TranslateModule } from '@ngx-translate/core'
import { IconPlusFilledComponent } from '@client-workspace/icons/plus_filled.component'
import { IconEditOutlineComponent } from '@client-workspace/icons/edit_outline.component'
import { IconMailFilledComponent } from '@client-workspace/icons/mail_filled.component'
import { IconDeleteOutlineComponent } from '@client-workspace/icons/delete_outline.component'
import { IconSearchFilledComponent } from '@client-workspace/icons/search_filled.component'
import { IconLockOrientationFilledComponent } from '@client-workspace/icons/lock_orientation_filled.component'
import { EditUserFormComponent } from '../forms/edit-user-form/edit-user-form.component'
import { UserActivationComponent } from '../forms/user-activation-dialog/user-activation-dialog.component'
import { AddUserFormComponent } from '../forms/add-user-form/add-user-form.component'
import { UserDeletionComponent } from '../forms/user-deletion-dialog/user-deletion-dialog.component'
import { IconCircleQuestionOutlineComponent } from '@client-workspace/icons/circle_question_outline.component'
import { ToastsService } from '@client-workspace/toasts'
import { TranslateJoinPipe } from '../../../-shared/pipes/translate-join.pipe'
import { highlightText } from '../../../-shared/utils/highlight-text.function'
import { UserManagementFacade } from '../+state/user-management.facade'
import { I18nUtilityService } from '../../../-shared/services/i18n.utility-service'
import { Role, User } from '../../../generated'
import { AppFacade } from '../../../+state/app.facade'
import { InputFieldComponent } from '@client-workspace/custom-components'

@Component({
  selector: 'app-user-management',
  standalone: true,
  providers: [DatePipe],
  templateUrl: './list-users.component.html',
  styleUrl: './list-users.component.scss',
  imports: [
    TranslateModule,
    DatePipe,
    MatSortModule,
    IconPlusFilledComponent,
    FormsModule,
    MatTooltipModule,
    IconEditOutlineComponent,
    IconMailFilledComponent,
    IconDeleteOutlineComponent,
    IconSearchFilledComponent,
    MatProgressSpinnerModule,
    TranslateJoinPipe,
    IconCircleQuestionOutlineComponent,
    IconLockOrientationFilledComponent,
    AsyncPipe,
    InputFieldComponent,
  ],
})
export class ListUsersComponent implements OnInit {
  protected readonly I18nUtilityService = I18nUtilityService
  userManagementFacade = inject(UserManagementFacade)
  app = inject(AppFacade)
  toastService = inject(ToastsService)

  users: User[] = []
  loaded$ = this.userManagementFacade.loaded$
  error$ = this.userManagementFacade.error$
  displayedColumns: string[] = ['firstName', 'lastName', 'email', 'role', 'createdAt', 'enabled', 'actions']
  searchInput = ''
  sortValue: Sort = { active: 'createdAt', direction: 'desc' }
  tableEl = viewChild<ElementRef>('tableEl')
  isWideScreen = true

  constructor(public dialog: MatDialog) {
    // TODO: do not subscribe, user async pipe instead, fot that we need to extract table component
    this.app.breakpoint$.subscribe((bp) => {
      this.isWideScreen = bp === 'xxl'
    })

    this.userManagementFacade.users$.subscribe((users) => {
      if (users) {
        this.users = users
      }
    })
  }

  ngOnInit(): void {
    this.userManagementFacade.init()
  }

  onSearchInputChange() {
    this.userManagementFacade.filter(this.searchInput)
    try {
      highlightText(this.searchInput, this.tableEl()?.nativeElement)
    } catch (e) {
      console.log(e)
    }
  }

  extractValue(user: User, label: string) {
    return user[label as keyof User] || '-'
  }

  openEditUserForm(user: User) {
    const config = { data: user }
    const dialogEdit = this.dialog.open(EditUserFormComponent, config)
    dialogEdit.afterClosed().subscribe((result) => {
      if (result) {
        this.userManagementFacade.update(result)
      }
    })
  }

  openToggleUserWarning(e: Event, user: User) {
    e.preventDefault()
    const config = { data: user, autoFocus: false }
    const dialogToggle = this.dialog.open(UserActivationComponent, config)
    dialogToggle.afterClosed().subscribe((user) => {
      if (user) {
        this.userManagementFacade.toggleEnabled({ ...user, enabled: !user.enabled })
      }
    })
  }

  openAddUserForm() {
    const dialogAdd = this.dialog.open(AddUserFormComponent)
    dialogAdd.afterClosed().subscribe((user) => {
      if (user) {
        this.userManagementFacade.create(user)
      }
    })
  }

  openDeleteUserWarning(user: User) {
    const config = { data: user, autoFocus: false }
    const dialogDelete = this.dialog.open(UserDeletionComponent, config)
    dialogDelete.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.userManagementFacade.delete(user)
      }
    })
  }

  prefixRoles(roles: Role[]) {
    return I18nUtilityService.prefixRoles(roles)
  }

  prefixTableHeader(title: string) {
    return I18nUtilityService.prefix(`userManagement.tableHeader.`, title)
  }

  sort(sort: Sort) {
    this.userManagementFacade.sort(sort)
  }
}
