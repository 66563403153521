import localeDe from '@angular/common/locales/de'
import localeDeExtra from '@angular/common/locales/extra/de'
import { registerLocaleData } from '@angular/common'
import { bootstrapApplication } from '@angular/platform-browser'
import { appConfig } from './app/app.config'
import { AppComponent } from './app/app.component'

registerLocaleData(localeDe, 'de-DE', localeDeExtra)

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err))
