import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, filter, map, of, switchMap } from 'rxjs'
import * as AdmissionActions from './admissions.actions'
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store'
import { Router } from '@angular/router'
import { AdmissionsEntity } from './admissions.models'
import { AdmissionsService } from '../admissions.service'

@Injectable()
export class AdmissionsEffects {
  private actions$ = inject(Actions)
  private admissionService = inject(AdmissionsService)
  private router = inject(Router)

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdmissionActions.initAdmissions),
      switchMap(() => of(AdmissionActions.loadAdmissionsSuccess({ admissions: [] }))),
      catchError((error) => {
        console.error('Error', error)
        return of(AdmissionActions.loadAdmissionsFailure({ error }))
      })
    )
  )

  createAdmission$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    filter(({ payload: { routerState: { url } } }: RouterNavigationAction) => url.startsWith('/admissions/create')),
    map(({ payload: { routerState: { root: { queryParams } } } }: RouterNavigationAction) => queryParams),
    // Got HIS/KIS parameters, need to process the data in an admission service before it gets into the store
    switchMap((queryParams) => this.admissionService.createAdmissionFromQueryParams(queryParams).pipe(
      map((admission: AdmissionsEntity) => AdmissionActions.createAdmissionsSuccess({ admission })),
      catchError((error) => of(AdmissionActions.createAdmissionsFailure(error)))
    ))
  ))

  processAdmissionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AdmissionActions.createAdmissionsSuccess),
    switchMap(({ admission }) => {
      this.router.navigate(['/admissions/process', admission.id]).then(() => of(AdmissionActions.doNothing()))
      return of(AdmissionActions.doNothing())
    })
  ))
}
