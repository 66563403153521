export const REALM_NAME = 'default'

export function getRealmNameFromUrl(url: string) {
  const regex = /\/realms\/([\w|\d|-]+)/gm
  const realmMatches = regex.exec(url)
  let realmName = window.sessionStorage.getItem('realm') ?? REALM_NAME
  if (realmMatches && realmMatches[1]) {
    realmName = realmMatches[1]
  }
  return realmName
}

export function replaceDeeplyNestedObj(arr: Array<any>, obj: any, key: string): any {
  for (const el of arr) {
    if (el[key] === obj[key]) {
      arr[arr.indexOf(el)] = obj
      return
    } else if (el.items) {
      replaceDeeplyNestedObj(el.items, obj, key)
    }
  }
}
export function deleteDeeplyNestedObj(arr: Array<any>, obj: any, key: string): any {
  for (const el of arr) {
    if (el[key] === obj[key]) {
      arr.splice(arr.indexOf(el), 1)
      return
    } else if (el.items) {
      deleteDeeplyNestedObj(el.items, obj, key)
    }
  }
}

export function flatten(someArray: any, result: Array<any> = []): Array<any> {
  if (Array.isArray(someArray)) {
    someArray.forEach((nested) => {
      return flatten(nested, result)
    })
  } else {
    result.push(someArray)
    if (someArray.items) {
      return flatten(someArray.items, result)
    }
  }
  return result
}
