import { createAction, props } from '@ngrx/store'
import { WorkflowEngineEntity } from './workflow-engine.models'
import { Workflow } from '../../../questionnaire-builder/models/fhir-interfaces'

export const initWorkflowEngine = createAction('[WorkflowEngine Page] Init')

export const loadWorkflowEngineSuccess = createAction(
  '[WorkflowEngine/API] Load WorkflowEngine Success',
  props<{ workflowEngine: WorkflowEngineEntity[] }>(),
)

export const loadWorkflowEngineFailure = createAction(
  '[WorkflowEngine/API] Load WorkflowEngine Failure',
  props<{ error: any }>(),
)

export const loadQuestionnaires = createAction('[Admissions/API] Load Admissions Failure')

export const saveWorkflow = createAction('[Builder/API] Save Workflow', props<{ workflow: Workflow }>())
