<div id="fhir-renderer">
  @if (activeQuestionnaire && activeQuestionnaire.item) {
    <h5 class="heading-m m-semibold">{{ activeQuestionnaire.title }}</h5>
    @for (activeQuestionnaireItem of activeQuestionnaire.item; track activeQuestionnaireItem.linkId; let i = $index) {
      <section class="card">
        <h6 class="heading-s">{{ activeQuestionnaireItem.text }}</h6>
        <div style="margin: 0 6px;"><cc-divider></cc-divider></div>
        <fhir-questionnaire-item
          class="fhir-renderer_top-level_item-group"
          (emitQuestionnaireItemChange)="questionnaireItemChange()"
          [questionnaireItem]="activeQuestionnaireItem"
        />
      </section>
    }
  }
</div>
