import { Component, EventEmitter, Input, Output } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { InputFieldComponent } from '@client-workspace/custom-components'
import { IconCircleCheckFilledComponent } from '@client-workspace/icons/circle_check_filled.component'
import { IconCircleCloseFilledComponent } from '@client-workspace/icons/circle_close_filled.component'
import { IconEyeShowOutlineComponent } from '@client-workspace/icons/eye_show_outline.component'
import { IconEyeHiddenOutlineComponent } from '@client-workspace/icons/eye_hidden_outline.component'
import { PASSWORD_RULES } from '../../../-shared/utils/validation-regex.const'

@Component({
  selector: 'app-password-guidelines',
  standalone: true,
  imports: [
    TranslateModule,
    InputFieldComponent,
    IconCircleCheckFilledComponent,
    IconCircleCloseFilledComponent,
    IconEyeShowOutlineComponent,
    IconEyeHiddenOutlineComponent,
  ],
  templateUrl: './password-guidelines.component.html',
  styleUrl: './password-guidelines.component.scss',
})
export class PasswordGuidelinesComponent {
  @Input() email = ''
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() passwordOutput: EventEmitter<string> = new EventEmitter<string>()
  password = ''
  passwordRules = [...PASSWORD_RULES]
  passwordVisible = false

  public checkPasswordPattern(): void {
    PASSWORD_RULES.map((rule) => {
      rule.isValid = this.password.match(rule.regex.pattern) != null
    })
    this.isValid.emit(this.password !== this.email && PASSWORD_RULES.every((rule) => rule.isValid))
    this.passwordOutput.emit(this.password)
  }
}
