import { Component, inject, Input, OnInit } from '@angular/core'

import { AdmissionsFacade } from '../+state/admissions-facade.service'
import { AsyncPipe } from '@angular/common'
import { AdmissionsEntity } from '../+state/admissions.models'
import { Observable } from 'rxjs'
import { FhirQuestionnaireResponse, QuestionnaireRendererComponent } from '@client-workspace/fhir-engine'

@Component({
  selector: 'app-process-admission',
  standalone: true,
  imports: [AsyncPipe, QuestionnaireRendererComponent],
  templateUrl: './process-admission.component.html',
  styleUrl: './process-admission.component.css'
})
export class ProcessAdmissionComponent implements OnInit {
  @Input() id!: string
  admissionFacade = inject(AdmissionsFacade)
  admission$!: Observable<AdmissionsEntity | undefined>

  ngOnInit(): void {
    this.admission$ = this.admissionFacade.selectAdmission(this.id)
  }

  updateQuestionnaireResponse(updatedQuestionnaireResponse: FhirQuestionnaireResponse) {
    this.admissionFacade.updateQuestionnaireResponseInAdmissionById(this.id, updatedQuestionnaireResponse)
  }
}
