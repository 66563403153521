<div [ngClass]="{ darken: requestSent }">
  <div class="inner-dialog">
    <div>
      <h2>
        {{ data.enabled ? ('userManagement.deactivateUserDialog.title' | translate) : ('userManagement.userActivationDialog.title' | translate) }}
      </h2>
      <app-icon-close-filled
        style="fill: var(--text-heading-primary)"
        mat-dialog-close
        class="dialog-close-button"
      ></app-icon-close-filled>
    </div>

    <p class="top-text">
      @if (data.enabled) {
        {{ 'userManagement.deactivateUserDialog.descriptionPart1' | translate }}
        <span class="m-bold">{{ userEmail }}</span>
        {{ 'userManagement.deactivateUserDialog.descriptionPart2' | translate }}
      } @else {
        {{ 'userManagement.activateUserDialog.descriptionPart1' | translate }}
        <span class="m-bold">{{ userEmail }}</span>
        {{ 'userManagement.activateUserDialog.descriptionPart2' | translate }}
      }
    </p>

    <button class="btn-primary width-100" [disabled]="requestSent" (click)="updateUser()">
      {{ data.enabled ? ('userManagement.deactivateUserDialog.submitButtonLabel' | translate) : ('userManagement.userActivationDialog.submitButtonLabel' | translate) }}
    </button>

    @if (apiReturnedError) {
      <p class="top-text error-message">
        {{ 'userManagement.userActivationDialog.userNotUpdatedMessage' | translate }}
      </p>
    }
  </div>
</div>

@if (requestSent) {
  <mat-spinner></mat-spinner>
}
