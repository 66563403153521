import { Component, Input } from '@angular/core'
import { RouterLink } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { IconClockAlertComponent } from '@client-workspace/icons/clock-alert.component'

@Component({
  selector: 'app-token-expired-screen',
  standalone: true,
  imports: [RouterLink, TranslateModule, IconClockAlertComponent],
  template: ` <section class="card">
    <div class="token-expired">
      <header class="m-text-center">
        <app-icon-clock-alert></app-icon-clock-alert>
        <h3 class="heading-m m-semibold">{{ 'auth.tokenExpired.passwordTokenInvalid' | translate }}</h3>
      </header>
      <div>
        <p class="text-m">{{ 'auth.tokenExpired.passwordTokenInvalidDescription' | translate }}</p>
      </div>
      @if (showButton) {
        <button class=" btn-primary width-100" [routerLink]="['/forgot-password']">
          {{ 'auth.tokenExpired.requestNewLinkLabel' | translate }}
        </button>
      }
    </div>
  </section>`,

  styles: [
    `
      .card {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
        max-width: 480px;
        margin-inline: auto;
      }

      .heading-m {
        margin-top: 20px;
      }
      .token-expired {
        display: flex;
        flex-direction: column;
        gap: 32px;
      }
    `,
  ],
})
export class TokenExpiredScreenComponent {
  @Input() showButton? = true
}
