<div class="wrapper">
  <section
    class="canvas"
    id="questionnaire-canvas"
    (drop)="dropHandler($event)"
    (dragover)="dragoverHandler($event)"
    (dragenter)="dragenterHandler($event)"
    (dragleave)="dragleaveHandler($event)"
  >
    <app-editor-renderer class="is-outter" [questions]="questionnaire" [title]="properties.title"></app-editor-renderer>
  </section>

  <div class="palette">
    <h3 class="heading-xs">Inhalt</h3>
    <section class="field-wrapper">
      @for (item of inhaltItems; track item.displayName + $index) {
        <div class="palette-item" draggable="true" (dragstart)="dragstartHandler($event, item)">
          {{ item.displayName }}
        </div>
      }
    </section>
    <cc-divider></cc-divider>
    <h3 class="heading-xs">Formularfelder</h3>
    <section class="field-wrapper">
      @for (item of paletteItems; track item.displayName + $index) {
        <div class="palette-item form-field" draggable="true" (dragstart)="dragstartHandler($event, item)">
          {{ item.displayName }}
        </div>
      }
    </section>
  </div>
</div>

@if (questionnaire.length && properties.name) {
  @if (properties.id) {
    <button class="btn-primary small" (click)="updateQuestionnaire()">Dokument updaten</button>
    <button class="btn-secondary small ml-10" (click)="saveQuestionnaire()">Neues Dokument speichern</button>
  } @else {
    <button class="btn-primary small" (click)="saveQuestionnaire()">Dokument speichern</button>
  }
}
