import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-home-filled',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.92 21.6H15.6C14.9371 21.6 14.4 21.0629 14.4 20.4V16.08C14.4 15.5496 13.9704 15.12 13.44 15.12H11.52C10.9896 15.12 10.56 15.5496 10.56 16.08V20.4C10.56 21.0629 10.0228 21.6 9.35997 21.6H5.03997C4.37709 21.6 3.83997 21.0629 3.83997 20.4V11.2382C3.83997 10.1347 4.34589 9.09216 5.21229 8.40961L12.034 3.03408C12.2956 2.82864 12.6643 2.82864 12.9254 3.03408L19.7481 8.40961C20.6145 9.09216 21.12 10.1342 21.12 11.2373V20.4C21.12 21.0629 20.5828 21.6 19.92 21.6Z"
    />
  </svg>`,
})
export class IconHomeFilledComponent {}
