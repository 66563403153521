import { inject, Injectable, signal } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs'
import * as AuthActions from './auth.actions'
import {
  AuthenticatedUserActionsService,
  ManageUserActionsService,
  SessionManagementService,
  SessionMetaData,
} from '../../../generated'
import { ToastsService } from '@client-workspace/toasts'
import * as AppActions from '../../../+state/app.actions'
import * as AppSelectors from '../../../+state/app.selectors'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { AuthFacade } from './auth.facade'
import * as AuthSelectors from './auth.selectors'
import { getRealmNameFromUrl } from '../../../-shared/utils/utils'

@Injectable()
export class AuthEffects {
  private actions$ = inject(Actions)
  private router = inject(Router)
  private generatedAuthService = inject(AuthenticatedUserActionsService)
  private generatedSessionManagementService = inject(SessionManagementService)
  private generatedUserManagementService = inject(ManageUserActionsService)
  private toastService = inject(ToastsService)
  private store = inject(Store)
  private authFacade = inject(AuthFacade)
  sessionExpiryDate = signal('')
  accessExpiryDate = signal('')
  accessTimeout!: ReturnType<typeof setTimeout> | null

  setTimeouts() {
    const timeout = new Date(this.accessExpiryDate()).getTime() - new Date().getTime() - 5000 // expiration time
    this.accessTimeout = setTimeout(() => {
      this.store.dispatch(AuthActions.refreshToken())
    }, timeout)
  }

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap((data) =>
        this.generatedSessionManagementService.login(data).pipe(
          map((data) => AuthActions.loginSuccess({ auth: data })),
          catchError((error) => of(AuthActions.loginFailure(error))),
        ),
      ),
    ),
  )

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      switchMap(() => {
        return of(AuthActions.getAuthenticatedUser())
      }),
    ),
  )

  loginFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginFailure),
      switchMap(({ error }) => {
        this.toastService.add({ type: 'error', msg: error.message })
        return of(AppActions.appReady())
      }),
    ),
  )

  getAuthenticatedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.initApp, AuthActions.getAuthenticatedUser),
      switchMap(() =>
        this.generatedAuthService.whoAmI().pipe(
          map((data) => AuthActions.getAuthenticatedUserSuccess({ user: data })),
          catchError((error) => of(AuthActions.getAuthenticatedUserFailure(error))),
        ),
      ),
    ),
  )

  restoreRealm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getAuthenticatedUserSuccess),
      switchMap(({ user }) => {
        const realm = getRealmNameFromUrl(window.location.href)
        if (realm !== user.realm) {
          const url = window.location.toString()
          window.location.href = url.replace(realm, user.realm)
          return of(AuthActions.restoreRealm({ before: realm, after: user.realm }))
        }
        return of(AuthActions.correctRealm({ message: `realm was correct: ${realm}`, effect: 'restoreRealm$' }))
      }),
    ),
  )
  refreshTokenOnUserPermissionsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getAuthenticatedUserFailure),
      switchMap(() =>
        this.generatedSessionManagementService.refreshSession().pipe(
          map((data) => AuthActions.refreshTokenSuccess({ sessionMetaData: data })),
          catchError((error) => of(AuthActions.refreshTokenFailure(error))),
        ),
      ),
    ),
  )

  refreshTokenAfterGetAuthenticatedUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getAuthenticatedUserSuccess),
      switchMap(() => of(AuthActions.refreshToken())),
    ),
  )

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshToken),
      switchMap(() =>
        this.generatedSessionManagementService.refreshSession().pipe(
          map((data) => AuthActions.refreshTokenSuccess({ sessionMetaData: data })),
          catchError((error) => of(AuthActions.refreshTokenFailure(error))),
        ),
      ),
    ),
  )

  refreshTokenSuccessOrFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshTokenSuccess, AuthActions.refreshTokenFailure),
      withLatestFrom(this.store.pipe(select(AppSelectors.selectAppReady))),
      switchMap(([, session]) => {
        return of(
          session
            ? AppActions.doNothing({ message: 'refreshTokenSuccessOrFailure$: session available' })
            : AppActions.appReady(),
        )
      }),
    ),
  )

  refreshTokenFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshTokenFailure),
      switchMap(() => {
        this.router.navigate(['/login'])
        return of(AppActions.userRedirected({ url: '/login' }))
      }),
    ),
  )

  refreshTokenSuccessButNoAuthenticatedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshTokenSuccess),
      withLatestFrom(this.store.pipe(select(AuthSelectors.selectCurrentUser))),
      switchMap(([, user]) => {
        return of(
          user
            ? AppActions.doNothing({
                message: 'refreshTokenSuccessButNoAuthenticatedUser$: user already exists',
                effect: 'refreshTokenSuccessButNoAuthenticatedUser$',
              })
            : AuthActions.getAuthenticatedUser(),
        )
      }),
    ),
  )

  refreshTokenSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshTokenSuccess),
      switchMap((auth: { type: string; sessionMetaData: SessionMetaData }) => {
        this.sessionExpiryDate.set(auth.sessionMetaData.sessionExpiryDate)
        this.accessExpiryDate.set(auth.sessionMetaData.accessExpiryDate)
        this.setTimeouts()
        return of(AppActions.doNothing({ message: 'setTimeout started', effect: 'refreshTokenSuccess$' }))
      }),
    ),
  )

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(() =>
        this.generatedSessionManagementService.authenticationControllerDestroySession().pipe(
          mergeMap(() => [AuthActions.logoutSuccess(), AuthActions.deleteSession()]),
          catchError((error) => of(AuthActions.logoutFailure(error))),
        ),
      ),
    ),
  )

  logoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logoutSuccess),
      switchMap(() => {
        this.accessTimeout = null
        this.router.navigate(['/login'])
        return of(AppActions.userRedirected({ url: '/login' }))
      }),
    ),
  )

  deleteSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.deleteSession),
      switchMap(() =>
        this.generatedSessionManagementService.authenticationControllerDestroySession().pipe(
          map(() => AuthActions.deleteSessionSuccess()),
          catchError((error) => of(AuthActions.deleteSessionFailure(error))),
        ),
      ),
    ),
  )

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.forgotPassword),
      switchMap((params) =>
        this.generatedUserManagementService.forgotPassword(params).pipe(
          map(() => AuthActions.forgotPasswordSuccess()),
          catchError((error) => {
            this.toastService.add({ msg: 'userManagement.serverErrorMessage', type: 'error' })
            return of(AuthActions.forgotPasswordFailure(error))
          }),
        ),
      ),
    ),
  )

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetPassword),
      switchMap((params) =>
        this.generatedUserManagementService.setPassword(params).pipe(
          map(() => AuthActions.resetPasswordSuccess()),
          catchError((error) => {
            this.toastService.add({
              msg: 'userManagement.serverErrorMessage',
              type: 'error',
            })
            return of(AuthActions.resetPasswordFailure(error))
          }),
        ),
      ),
    ),
  )

  checkTokenValidity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.checkTokenValidity),
      switchMap((params) =>
        this.generatedUserManagementService.validateToken(params).pipe(
          map(() => AuthActions.checkTokenValiditySuccess()),
          catchError(({ error }) => {
            this.toastService.add({ msg: error.message, type: 'error' })
            return of(AuthActions.checkTokenValidityFailure(error))
          }),
        ),
      ),
    ),
  )
}
