import { Component } from '@angular/core'

import { ActivatedRoute, Params } from '@angular/router'

@Component({
  selector: 'app-create-admission',
  standalone: true,
  imports: [],
  templateUrl: './create-admission.component.html',
  styleUrl: './create-admission.component.css'
})
export class CreateAdmissionComponent {
  params: Params | undefined

  constructor(private route: ActivatedRoute) {
    route.queryParams.subscribe((params: Params) => {

      this.params = params
      // console.log(params)
      // window.location.replace()
    })
  }

}

// example url
// http://localhost:4200/realms/master/admissions/create?fn=Erika&ln=Musterfrau&bd=01.01.1900&id=PID12345&cn=FID067890&gd=female&cell=015770571155&email=erika.musterfrau@web.de&insuranceName=AOK%20-%20Die%20Gesundheitskasse&insuredNo=A123456789&custom.ZV=Zahnversicherung&custom.Nummer_ZV=A987654321&ward=Kieferchirurgie&room=420&custom.1-Bett-Zimmer=true&custom.2-Bett-Zimmer=false
