import { Component, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AdmissionsFacade } from '../+state/admissions-facade.service'
import { RouterLink } from '@angular/router'

@Component({
  selector: 'app-list-admissions',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './list-admissions.component.html',
  styleUrl: './list-admissions.component.scss',
})
export class ListAdmissionsComponent {
  admissionFacade = inject(AdmissionsFacade)
  admissions$ = this.admissionFacade.allAdmissions$
}
