import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { createReducer, on, Action } from '@ngrx/store'

import * as WorkflowEngineActions from './workflow-engine.actions'
import { WorkflowEngineEntity } from './workflow-engine.models'

export const WORKFLOW_ENGINE_FEATURE_KEY = 'workflowEngine'

export interface WorkflowEngineState extends EntityState<WorkflowEngineEntity> {
  selectedId?: string | number // which WorkflowEngine record has been selected
  loaded: boolean // has the WorkflowEngine list been loaded
  error?: string | null // last known error (if any)
}

export interface WorkflowEnginePartialState {
  readonly [WORKFLOW_ENGINE_FEATURE_KEY]: WorkflowEngineState
}

export const workflowEngineAdapter: EntityAdapter<WorkflowEngineEntity> = createEntityAdapter<WorkflowEngineEntity>()

export const initialWorkflowEngineState: WorkflowEngineState = workflowEngineAdapter.getInitialState({
  // set initial required properties
  loaded: false,
})

const reducer = createReducer(
  initialWorkflowEngineState,
  on(WorkflowEngineActions.initWorkflowEngine, (state) => ({ ...state, loaded: false, error: null })),
  on(WorkflowEngineActions.loadWorkflowEngineSuccess, (state, { workflowEngine }) =>
    workflowEngineAdapter.setAll(workflowEngine, { ...state, loaded: true }),
  ),
  on(WorkflowEngineActions.loadWorkflowEngineFailure, (state, { error }) => ({ ...state, error })),

  on(WorkflowEngineActions.saveWorkflow, (state, { workflow }) => {
    fetch('http://localhost:8080/services/crud-playground/generic/workflows', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(workflow),
    })
    return state
  }),
)

export function workflowEngineReducer(state: WorkflowEngineState | undefined, action: Action) {
  return reducer(state, action)
}
