import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-eye-hidden-outline',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.005 2.15927C22.794 2.16542 22.5937 2.25345 22.4464 2.40472L2.40475 22.4464C2.32677 22.5213 2.26451 22.6109 2.22163 22.7102C2.17874 22.8094 2.15609 22.9162 2.15499 23.0243C2.15389 23.1324 2.17437 23.2396 2.21523 23.3397C2.25609 23.4397 2.31651 23.5307 2.39294 23.6071C2.46938 23.6835 2.5603 23.744 2.66038 23.7848C2.76046 23.8257 2.86769 23.8462 2.97579 23.8451C3.08388 23.844 3.19067 23.8213 3.2899 23.7784C3.38913 23.7355 3.4788 23.6733 3.55367 23.5953L9.16288 17.9861C10.1209 19.0229 11.4836 19.6788 13 19.6788C15.8871 19.6788 18.2379 17.3279 18.2379 14.4462C18.2379 12.9297 17.5821 11.563 16.5452 10.6038L18.9869 8.16204C21.615 9.70079 23.654 12.1858 24.4025 15.0958C24.4946 15.4641 24.8246 15.7083 25.1875 15.7083C25.2525 15.7083 25.3225 15.7025 25.3875 15.6808C25.8262 15.5725 26.0863 15.1292 25.9725 14.6959C25.1385 11.4445 22.9999 8.70627 20.1919 6.95704L23.5953 3.55364C23.7126 3.43948 23.7926 3.2926 23.8249 3.13221C23.8573 2.97181 23.8405 2.80539 23.7767 2.65472C23.7129 2.50404 23.6051 2.37614 23.4674 2.28774C23.3297 2.19934 23.1686 2.15456 23.005 2.15927ZM12.9948 4.87501C6.94433 4.87501 1.49005 9.00298 0.027548 14.6959C-0.086202 15.1292 0.17384 15.5725 0.61259 15.6808C1.04592 15.7946 1.4892 15.5345 1.59753 15.0958C2.85962 10.1937 7.76225 6.50001 12.9948 6.50001C13.921 6.50001 14.836 6.6133 15.719 6.83538L17.052 5.50343C15.7574 5.09176 14.3923 4.87501 12.9948 4.87501ZM13 9.20834C10.113 9.20834 7.76746 11.5591 7.76746 14.4462C7.76746 14.5546 7.77262 14.668 7.77804 14.7763L9.8315 12.7239C10.1619 12.1118 10.6709 11.6028 11.283 11.2724L13.3354 9.21892C13.2217 9.21351 13.1138 9.20834 13 9.20834ZM15.3973 11.7516C16.1398 12.4131 16.6129 13.3724 16.6129 14.4462C16.6129 16.4341 14.9934 18.0538 13 18.0538C11.9305 18.0538 10.972 17.5807 10.3108 16.8382L15.3973 11.7516Z"
    />
  </svg>`,
})
export class IconEyeHiddenOutlineComponent {}
