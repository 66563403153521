import { Component } from '@angular/core'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatSelectModule } from '@angular/material/select'
import { MatDialogClose, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { IconCloseFilledComponent } from '@client-workspace/icons/close_filled.component'
import { CommonModule } from '@angular/common'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { ToastsService } from '@client-workspace/toasts'
import { InputFieldComponent } from '@client-workspace/custom-components'
import { EMAIL_REGEX } from '../../../../-shared/utils/validation-regex.const'
import { I18nUtilityService } from '../../../../-shared/services/i18n.utility-service'
import { Role } from '../../../../generated'

@Component({
  selector: 'app-add-user-form',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogClose,
    MatDialogModule,
    IconCloseFilledComponent,
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
    InputFieldComponent,
  ],
  templateUrl: './add-user-form.component.html',
  styleUrl: './add-user-form.component.scss',
})
export class AddUserFormComponent {
  i18nUtility = I18nUtilityService
  public roleList = Object.values(Role)
  public rolesFormControl = new FormControl([])
  public inputFirstName = ''
  public inputLastName = ''
  public inputEMail = ''

  public checkedEMailPattern = false
  public wrongEMailPattern = false
  public requestSent = false
  public firstNameInputIsDirty = false
  public lastNameInputIsDirty = false

  private emailRegex = EMAIL_REGEX

  constructor(
    private toastsService: ToastsService,
    private dialog: MatDialogRef<AddUserFormComponent>,
    private translation: TranslateService,
  ) {}

  public checkEMailPattern(): void {
    if (this.emailRegex.test(this.inputEMail)) {
      this.checkedEMailPattern = true
      this.wrongEMailPattern = false
    } else {
      this.checkedEMailPattern = false
      this.wrongEMailPattern = true
    }
  }

  public async createUser(): Promise<void> {
    this.requestSent = true

    this.dialog.close({
      firstName: this.inputFirstName,
      lastName: this.inputLastName,
      email: this.inputEMail,
      roles: this.rolesFormControl?.getRawValue() || [],
    })
    /*const { data, error } = await this.userService.createUser({
      firstName: this.inputFirstName,
      lastName: this.inputLastName,
      email: this.inputEMail,
      roles: this.rolesFormControl?.getRawValue() || [],
    })

    this.requestSent = false

    if (data) {
      this.dialog.close(data)
      this.toastsService.add({
        type: 'success',
        msg: this.translation.instant('userManagement.addUserForm.createdSuccessMessage'),
      })
    }
    if (error) {
      this.toastsService.add({
        type: 'error',
        msg: this.translation.instant('userManagement.addUserForm.creationFailureMessage'),
      })
      // TODO: this.toastsService.add({ type: 'error', msg: this.translation.instant((error as { message: string, error: string, statusCode: number }).message) })
    }*/
  }

  public isNameValid(name: string) {
    return name.length > 0 && name.length < 256
  }

  prefixRole(role: Role) {
    return I18nUtilityService.prefixRole(role)
  }
}
