import { Injectable } from '@angular/core'
import { Params } from '@angular/router'
import { Observable, of } from 'rxjs'
import { initialAdmissionQuestionnaireDummy } from './_mocks/patientenaufnahme-questionnaire'
import { AdmissionsEntity } from './+state/admissions.models'
import { v4 as uuidv4 } from 'uuid'
import { ActiveQuestionnaire, FhirQuestionnaire } from '@client-workspace/fhir-engine'
import { Questionnaire } from '../../questionnaire-builder/models/fhir-interfaces'

/* Currently known HIS-Parameters:
 * http://localhost:4200/realms/master/admissions/create
 * ?fn=erika
 * &ln=musterfrau
 * &bd=01.01.1900
 * &id=PID12345
 * &cn=FID067890
 * &gd=female
 * &cell=015770571155
 * &email=erika.musterfrau@web.de
 * &insuranceName=AOK%20-%20Die%20Gesundheitskasse
 * &insuredNo=A123456789
 * &custom.ZV=Zahnversicherung
 * &custom.Nummer_ZV=A987654321
 * &ward=Kieferchirurgie
 * &room=420
 * &custom.1-Bett-Zimmer=true
 * &custom.2-Bett-Zimmer=false
 */

@Injectable({
  providedIn: 'root',
})
export class AdmissionsService {
  get questionnaire(): FhirQuestionnaire {
    return this._questionnaire
  }

  set questionnaire(value: Questionnaire) {
    this._questionnaire = value
  }

  private _questionnaire: Questionnaire

  constructor() {
    this._questionnaire = initialAdmissionQuestionnaireDummy
  }

  createAdmissionFromQueryParams(queryParams: Params): Observable<AdmissionsEntity> {
    return of({
      id: uuidv4(),
      name: 'Initial Admission',
      queryParams,
      questionnaire: this._questionnaire, // TODO: get default admission questionnaire from backend
      questionnaireResponse: this.createQuestionnaireResponseFromQueryParams(queryParams, this._questionnaire),
    })
  }

  private queryParamsToMap(queryParams: Params) {
    const queryParamsMap = new Map<string, string>()
    Object.keys(queryParams).forEach((key: string) => {
      queryParamsMap.set(key, queryParams[key])
    })
    return queryParamsMap
  }

  private createQuestionnaireResponseFromQueryParams(queryParams: Params, questionnaire: Questionnaire) {
    const activeQuestionnaire = new ActiveQuestionnaire(questionnaire)
    activeQuestionnaire.populateByQueryParams(this.queryParamsToMap(queryParams))
    return activeQuestionnaire.extractResponse()
  }
}
