import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ADMISSIONS_FEATURE_KEY, admissionsAdapter, AdmissionsState } from './admissions.reducer'

// Lookup the 'Admission' feature state managed by NgRx
export const selectAdmissionsState = createFeatureSelector<AdmissionsState>(ADMISSIONS_FEATURE_KEY)

const { selectAll, selectEntities } = admissionsAdapter.getSelectors()

export const selectAdmissionsLoaded = createSelector(selectAdmissionsState, (state: AdmissionsState) => state.loaded)

export const selectAdmissionsError = createSelector(selectAdmissionsState, (state: AdmissionsState) => state.error)


export const selectAllAdmissions = createSelector(selectAdmissionsState, (state: AdmissionsState) => selectAll(state))

export const selectAdmissionsEntities = createSelector(selectAdmissionsState, (state: AdmissionsState) => selectEntities(state))

export const selectSelectedId = createSelector(selectAdmissionsState, (state: AdmissionsState) => state.selectedId)

export const selectAdmission = (id: string) => createSelector(selectAdmissionsEntities, entities => entities[id])

export const selectEntity = createSelector(selectAdmissionsEntities, selectSelectedId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
)
