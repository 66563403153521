import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-home-outline',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9756 2C11.8157 2.00546 11.6617 2.06192 11.5361 2.16113L4.42969 7.75976C3.52774 8.47058 3 9.55675 3 10.7051V20.25C3 20.9318 3.5682 21.5 4.25 21.5H9.25C9.9318 21.5 10.5 20.9318 10.5 20.25V15.25C10.5 15.1025 10.6025 15 10.75 15H13.25C13.3975 15 13.5 15.1025 13.5 15.25V20.25C13.5 20.9318 14.0682 21.5 14.75 21.5H19.75C20.4318 21.5 21 20.9318 21 20.25V10.7051C21 9.55675 20.4723 8.47058 19.5703 7.75976L12.4639 2.16113C12.3252 2.05157 12.1522 1.9945 11.9756 2ZM12 3.70507L18.6426 8.93847C19.1846 9.36566 19.5 10.0154 19.5 10.7051V20H15V15.25C15 14.2925 14.2075 13.5 13.25 13.5H10.75C9.79252 13.5 9 14.2925 9 15.25V20H4.5V10.7051C4.5 10.0154 4.81537 9.36566 5.35742 8.93847L12 3.70507Z"
    />
  </svg>`,
})
export class IconHomeOutlineComponent {}
