import { Component, inject, OnInit } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { InputFieldComponent } from '@client-workspace/custom-components'
import { IconCircleCheckFilledComponent } from '@client-workspace/icons/circle_check_filled.component'
import { IconCircleCloseFilledComponent } from '@client-workspace/icons/circle_close_filled.component'
import { ActivatedRoute } from '@angular/router'
import { IconEyeShowOutlineComponent } from '@client-workspace/icons/eye_show_outline.component'
import { IconEyeHiddenOutlineComponent } from '@client-workspace/icons/eye_hidden_outline.component'
import { PasswordGuidelinesComponent } from '../password-guidelines/password-guidelines.component'
import { PasswordUpdatedScreenComponent } from '../reset-password/password-updated-screen.component'
import { TokenExpiredScreenComponent } from '../reset-password/token-expired-screen.component'
import { AuthFacade } from '../+state/auth.facade'
import { AsyncPipe } from '@angular/common'

@Component({
  selector: 'app-initial-password',
  standalone: true,
  imports: [
    TranslateModule,
    InputFieldComponent,
    IconCircleCheckFilledComponent,
    IconCircleCloseFilledComponent,
    IconEyeShowOutlineComponent,
    IconEyeHiddenOutlineComponent,
    PasswordGuidelinesComponent,
    PasswordUpdatedScreenComponent,
    TokenExpiredScreenComponent,
    AsyncPipe,
  ],
  templateUrl: './initial-password.component.html',
  styleUrl: './initial-password.component.scss',
})
export class InitialPasswordComponent implements OnInit {
  email = ''
  token = ''
  userPassword = ''
  passwordIsValid = false
  auth = inject(AuthFacade)
  passwordUpdated$ = this.auth.isPasswordUpdated$
  isTokenValid$ = this.auth.isTokenValid$

  constructor(private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe((params) => (this.email = params['email']))

    this.route.queryParams.subscribe((params) => {
      this.email = params['email']
      this.token = params['token']
    })
    this.auth.checkTokenValidity({ email: this.email, token: this.token })
  }

  handleSetPassword() {
    if (!this.email || !this.token || !this.passwordIsValid) {
      return
    }
    this.auth.resetPassword({
      tokenCredentials: {
        email: this.email,
        token: this.token,
      },
      newPassword: this.userPassword,
    })
  }

  updatePasswordString(value: string) {
    this.userPassword = value
  }

  checkPassword(value: boolean) {
    this.passwordIsValid = value
  }
}
