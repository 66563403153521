import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, of, switchMap } from 'rxjs'
import * as UserManagementActions from './user-management.actions'
import { ManageUsersService } from '../../../generated'
import { Store } from '@ngrx/store'
import { ToastsService } from '@client-workspace/toasts'
import { TranslateService } from '@ngx-translate/core'

@Injectable()
export class UserManagementEffects {
  private actions$ = inject(Actions)
  private store = inject(Store)
  private userManagementService = inject(ManageUsersService)
  private toastsService = inject(ToastsService)
  private translate = inject(TranslateService)

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserManagementActions.initUserManagement),
      switchMap((_, loaded) =>
        this.userManagementService.getUsers().pipe(
          map((users) => UserManagementActions.loadUserManagementSuccess({ users })),
          catchError((error) => {
            console.error('Error', error)
            return of(UserManagementActions.loadUserManagementFailure({ error }))
          }),
        ),
      ),
    ),
  )

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserManagementActions.updateUser),
      switchMap(({ user }) =>
        this.userManagementService.updateUser(user.id, user).pipe(
          map(() => {
            this.toastsService.add({
              type: 'success',
              msg: this.translate.instant('userManagement.editUserForm.userUpdatedSuccess', { userMail: user?.email }),
            })
            return UserManagementActions.updateUserSuccess({ user })
          }),
          catchError(({ error }) => {
            console.error('Error', error)
            this.toastsService.add({
              type: 'error',
              msg: this.translate.instant('userManagement.editUserForm.userUpdatedFailure'),
            })
            return of(UserManagementActions.updateUserFailure({ error }))
          }),
        ),
      ),
    ),
  )

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserManagementActions.createUser),
      switchMap(({ user }) =>
        this.userManagementService.createUser(user).pipe(
          map((user) => {
            this.toastsService.add({
              type: 'success',
              msg: 'userManagement.userCreation.successful',
            })
            return UserManagementActions.createUserSuccess({ user })
          }),
          catchError(({ error }) => {
            this.toastsService.add({
              type: 'error',
              msg: error.message,
            })
            console.error('Error', error)
            return of(UserManagementActions.createUserFailure({ error }))
          }),
        ),
      ),
    ),
  )

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserManagementActions.deleteUser),
      switchMap(({ user }) =>
        this.userManagementService.deleteUser(user.id).pipe(
          map(() => {
            this.toastsService.add({
              type: 'success',
              msg: this.translate.instant('userManagement.userDeletionDialog.userDeletedSuccessMessage', {
                userMail: user.email,
              }),
            })

            return UserManagementActions.deleteUserSuccess({ user })
          }),
          catchError((error) => {
            console.error('Error', error)
            this.toastsService.add({
              type: 'error',
              msg: 'userManagement.editUserForm.userUpdatedFailure',
            })
            return of(UserManagementActions.deleteUserFailure({ error }))
          }),
        ),
      ),
    ),
  )

  patchEnabledStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserManagementActions.patchEnabledStatus),
      switchMap(({ user }) =>
        this.userManagementService.patchEnabledStatus(user.id, { enabled: user.enabled }).pipe(
          map((_) => {
            return UserManagementActions.patchEnabledStatusSuccess({
              user: { id: user.id, changes: { enabled: _.enabled } },
            })
          }),
          catchError((error) => {
            console.error('Error', error)
            return of(UserManagementActions.patchEnabledStatusFailure({ error }))
          }),
        ),
      ),
    ),
  )
}
