import { Injectable, inject } from '@angular/core'
import { createEffect, Actions, ofType } from '@ngrx/effects'
import { switchMap, catchError, of } from 'rxjs'
import * as BuilderActions from './builder.actions'
import * as AppActions from '../../+state/app.actions'
import { Router } from '@angular/router'

@Injectable()
export class BuilderEffects {
  private actions$ = inject(Actions)
  private router = inject(Router)

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BuilderActions.initBuilder),
      switchMap(() => of(BuilderActions.loadBuilderSuccess({ builder: [] }))),
      catchError((error) => {
        console.error('Error', error)
        return of(BuilderActions.loadBuilderFailure({ error }))
      }),
    ),
  )

  resetState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[BuilderActions.updateQuestionnaire, BuilderActions.saveQuestionnaire]),
      switchMap(() => {
        const redirectPage = '/questionnaire-builder/list'
        this.router.navigate([redirectPage])
        return of(AppActions.userRedirected({ url: redirectPage }))
      }),
      catchError((error) => {
        console.error('Error', error)
        return of(BuilderActions.loadBuilderFailure({ error }))
      }),
    ),
  )
}
