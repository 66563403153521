import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'

import * as AppActions from './app.actions'
import { AppEntity, Breakpoint } from './app.models'

export const APP_FEATURE_KEY = 'app'

export interface AppState extends EntityState<AppEntity> {
  selectedId?: string | number // which App record has been selected
  loaded: boolean // has the App list been loaded
  realm?: string | null // has the App list been loaded
  error?: string | null | Error // last known error (if any)
  breakpoint: Breakpoint
  ready: boolean
}

export interface AppPartialState {
  readonly [APP_FEATURE_KEY]: AppState
}

export const appAdapter: EntityAdapter<AppEntity> = createEntityAdapter<AppEntity>()

export const initialAppState: AppState = appAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  breakpoint: 'xxl',
  realm: null,
  ready: false
})

const reducer = createReducer(
  initialAppState,
  on(AppActions.initApp, (state) => ({ ...state, loaded: false, error: null, ready: false })),
  on(AppActions.loadAppSuccess, (state, { app }) => appAdapter.setAll(app, { ...state, loaded: true })),
  on(AppActions.loadAppFailure, (state, { error }) => ({ ...state, error })),
  on(AppActions.appReady, (state) => ({ ...state, ready: true })),
  on(AppActions.appNotReady, (state) => ({ ...state, ready: false })),
  on(AppActions.appBreakpointChange, (state, { breakpoint }) => ({ ...state, breakpoint }))
)

export function appReducer(state: AppState | undefined, action: Action) {
  return reducer(state, action)
}
