import { inject, Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'

import * as AppActions from './app.actions'
// import * as AppFeature from './app.reducer'
import * as AppSelectors from './app.selectors'
import { Breakpoint } from './app.models'

@Injectable()
export class AppFacade {
  private readonly store = inject(Store)

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(AppSelectors.selectAppLoaded))
  allApp$ = this.store.pipe(select(AppSelectors.selectAllApp))
  breakpoint$ = this.store.pipe(select(AppSelectors.selectBreakpoint))
  ready$ = this.store.pipe(select(AppSelectors.selectAppReady))

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(AppActions.initApp())
  }

  breakpointChange(breakpoint: Breakpoint) {
    this.store.dispatch(AppActions.appBreakpointChange({ breakpoint }))
  }

  notReady() {
    this.store.dispatch(AppActions.appNotReady())
  }

  ready() {
    this.store.dispatch(AppActions.appReady())
  }
}
