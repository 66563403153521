<cc-input-field
  label="{{ 'defaultPasswordPolicy.label' | translate }}"
  placeholder="{{ 'defaultPasswordPolicy.label' | translate }}"
  [(value)]="password"
  (valueChange)="checkPasswordPattern()"
  type="{{ passwordVisible ? 'text' : 'password' }}"
>
  <button class="btn-plain additional-icon" (click)="passwordVisible = !passwordVisible">
    @if (passwordVisible) {
      <app-icon-eye-hidden-outline></app-icon-eye-hidden-outline>
    } @else {
      <app-icon-eye-show-outline></app-icon-eye-show-outline>
    }
  </button>
</cc-input-field>

<section class="password-rule-wrapper">
  <div class="password-rule-title text-xs m-bold">{{ 'defaultPasswordPolicy.safetyRequirements' | translate }}</div>
  @for (rule of passwordRules; track rule.description) {
    <div class="password-rule">
      <i>
        @if (rule.isValid) {
          <app-icon-circle-check-filled class="icon"></app-icon-circle-check-filled>
        } @else {
          <app-icon-circle-close-filled class="icon"></app-icon-circle-close-filled>
        }
      </i>
      <span class="text-xs">{{ rule.description | translate }}</span>
    </div>
  }
  <div class="password-rule">
    <i>
      @if (password !== email) {
        <app-icon-circle-check-filled class="icon"></app-icon-circle-check-filled>
      } @else {
        <app-icon-circle-close-filled class="icon"></app-icon-circle-close-filled>
      }
    </i>
    <span class="text-xs">{{ 'defaultPasswordPolicy.hasMatchingPasswords' | translate }}</span>
  </div>
</section>
