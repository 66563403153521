// nx g ngrx-feature-store admission --parent=apps/client/src/app/editor/editor.routes.ts --route="editor" //feature store in route

import { type Routes } from '@angular/router'
import { QuestionnaireBuilderComponent } from './questionnaire-builder.component'
import { provideState } from '@ngrx/store'
import { provideEffects } from '@ngrx/effects'
import * as fromBuilder from './+state/builder.reducer'
import { BuilderEffects } from './+state/builder.effects'
import { BuilderFacade } from './+state/builder.facade'
import { QuestionnairesListComponent } from './questionnaires-list/questionnaires-list.component'
/*
  IMPORTANT: App base is set in appConfig via APP_BASE_HREF and it should be /realms/{realmName}
  All routes are sub-pages of that base url, i.e. /home is basically realm/realm-name/home
*/
export const questionnaireBuilderRoutes: Routes = [
  {
    path: 'questionnaire-builder',
    children: [
      {
        path: '',
        redirectTo: '/questionnaire-builder/list',
        pathMatch: 'full',
        title: 'CaaS - Questionnaire Editor',
      },
      {
        path: 'new',
        component: QuestionnaireBuilderComponent,
        title: 'CaaS - Questionnaire Editor',
      },
      {
        path: 'list',
        component: QuestionnairesListComponent,
        title: 'CaaS - Questionnaires List',
      },
      {
        path: ':id',
        component: QuestionnaireBuilderComponent,
        title: 'CaaS - Questionnaire Editor',
      },
    ],
    providers: [
      BuilderFacade,
      provideState(fromBuilder.BUILDER_FEATURE_KEY, fromBuilder.builderReducer),
      provideEffects(BuilderEffects),
    ],
  },
]
