import { Component, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core'
import { QuestionnaireItem } from '../models/fhir-interfaces'
import {
  ButtonElementOption,
  InputFieldComponent,
  ToggleButtonsComponent,
  Value,
} from '@client-workspace/custom-components'
import { SelectFieldComponent } from '@client-workspace/custom-components'
import { AngularSignaturePadModule, NgSignaturePadOptions } from '@mineminemine/angular-signature-pad'

@Component({
  standalone: true,
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  imports: [InputFieldComponent, ToggleButtonsComponent, SelectFieldComponent, AngularSignaturePadModule],
})
export class QuestionComponent {
  @Input() userEditable = false
  @Input() question: QuestionnaireItem = { linkId: '', text: '', type: '' }
  fieldOptions: ButtonElementOption[] = []
  selectedOption: Value = ''

  signaturePadOptions: any = {
    minWidth: 1,
    canvasWidth: 600,
    canvasHeight: 200,
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.question.answerOptions) return
    this.fieldOptions = this.question.answerOptions
      ? (this.question.answerOptions?.map((answerOption) => ({
          label: answerOption.valueCoding?.display,
          value: answerOption.valueCoding?.display,
          checked: answerOption.initialSelected,
        })) as ButtonElementOption[])
      : ([] as ButtonElementOption[])
    this.selectedOption = this.fieldOptions.find((el) => el.checked)?.value || ''
  }
}
