@if ((isTokenValid$ | async) === undefined) {
  <div class="m-flex-center">
    <mat-spinner></mat-spinner>
  </div>
} @else if ((isTokenValid$ | async) === false) {
  <app-token-expired-screen [showButton]="false"></app-token-expired-screen>
} @else {
  <section class="card" data-testid="verify-email-form">
    <app-icon-circle-check-filled class="success-icon m-centered"></app-icon-circle-check-filled>
    <h3 class="title heading-m m-semibold">{{ 'auth.verifyEmail.verifyEmailSuccessMessage' | translate }}</h3>
    <button class="btn-primary width-100" [routerLink]="['/login']">
      {{ 'auth.toLoginButtonLabel' | translate }}
    </button>
  </section>
}
