import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-circle-info-filled',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_923_20545)">
      <path
        d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12.75 16.75C12.75 17.164 12.414 17.5 12 17.5C11.586 17.5 11.25 17.164 11.25 16.75V11.25C11.25 10.836 11.586 10.5 12 10.5C12.414 10.5 12.75 10.836 12.75 11.25V16.75ZM12 9C11.4475 9 11 8.5525 11 8C11 7.4475 11.4475 7 12 7C12.5525 7 13 7.4475 13 8C13 8.5525 12.5525 9 12 9Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_923_20545"><rect width="24" height="24" /></clipPath>
    </defs>
  </svg>`,
})
export class IconCircleInfoFilledComponent {}
