import { Routes } from '@angular/router'
import { ListUsersComponent } from './list/list-users.component'
import { AuthGuard } from '../../-shared/guards/auth.guard'
import { provideState } from '@ngrx/store'
import { provideEffects } from '@ngrx/effects'
import * as fromUserManagement from './+state/user-management.reducer'
import { UserManagementEffects } from './+state/user-management.effects'
import { UserManagementFacade } from './+state/user-management.facade'

export const userManagementRoutes: Routes = [
  {
    path: 'user-management',
    component: ListUsersComponent,
    canActivate: [AuthGuard],
    title: 'CaaS - User management',
    data: { authorization: 'crud-user' },
    providers: [
      UserManagementFacade,
      provideState(fromUserManagement.USER_MANAGEMENT_FEATURE_KEY, fromUserManagement.userManagementReducer),
      provideEffects(UserManagementEffects),
    ],
  },
]
