@if (label) {
  <span class="text-xs m-semibold">{{ label }}</span>
}

<div class="field" (click)="toggleOpen()" tabindex="0">
  <span class="label-text text-m m-ellipsis">{{ inputLabel() }}</span>

  <app-icon-chevron-down-outline class="chevron" [class]="isOpen ? 'is-open' : ''"></app-icon-chevron-down-outline>
</div>
@if (isOpen) {
  <div class="dropdown" [class]="multiple ? 'allow-multiple' : 'allow-single'">
    @for (option of internalOptions; track option) {
      <label class="option" #option>
        <input
          type="checkbox"
          class="text-s"
          tabindex="-1"
          [value]="option.value"
          [attr.data-index]="$index"
          [checked]="internalSelected().includes(option.value)"
          (change)="handleChange($event)"
        />
        {{ option.label }}
      </label>
    }
  </div>
}
