export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const PASSWORD_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// These rules come from https://authentication.evolution.taal.thieme.de/v1/password-policy
// https://jira.thieme-compliance.de/browse/CSD-117
export const PASSWORD_RULES = [
  {
    description: 'defaultPasswordPolicy.containsLowerCaseCharacters',
    regex: { pattern: '[a-z]' },
    isValid: false,
  },
  {
    description: 'defaultPasswordPolicy.containsUpperCaseCharacters',
    regex: { pattern: '[A-Z]' },
    isValid: false,
  },
  {
    description: 'defaultPasswordPolicy.containsNumerals',
    regex: { pattern: '[0-9]' },
    isValid: false,
  },
  {
    description: 'defaultPasswordPolicy.containsSpecialCharacters',
    regex: { pattern: '[,;:.@\\\\<>|+~\'#\\[\\]\\-_!"§$%&/()=?]' },
    isValid: false,
  },
  {
    description: 'defaultPasswordPolicy.hasMinimumLength',
    regex: { pattern: '.{10,}' },
    isValid: false,
  },
]
