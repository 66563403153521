import { Role, User } from '../../../generated'
import { Sort } from '@angular/material/sort'
import { Injectable } from '@angular/core'


@Injectable({
  providedIn: 'root',
})
export class UserRepository {

  static compare(a: string | boolean | Role[] | undefined, b: string | boolean | Role[] | undefined, isAsc: boolean) {
    if (a === '' || a === undefined) return isAsc ? 1 : -1
    if (b === '' || b === undefined) return isAsc ? -1 : 1
    if (a === b) return 0
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1)
  }

  static sortData(sort: Sort, list?: User[]) {
    if (!list) return
    if (!sort.direction) {
      return list
    }

    const data = list.slice()
    const isAsc = sort.direction === 'asc'
    const sortKey = (sort.active as keyof User) ?? 'id'
    return data.sort((a, b) => {
      return this.compare(a[sortKey], b[sortKey], isAsc)
    })
  }

  static filterListByString(value = '', list: User[]) {
    const searchVal = value.toLowerCase()
    return list.reduce((list: User[], user: User) => {
      if (
        // UserRepository.datePipe.transform(user.createdAt, 'short')?.includes(searchVal) ||
        user.firstName?.toLowerCase()?.includes(searchVal) ||
        user.lastName?.toLowerCase()?.includes(searchVal) ||
        user.email?.toLowerCase()?.includes(searchVal)
        // user.roles.some((role) => UserRepository.translateService.instant(role).toLowerCase()?.includes(searchVal))
      ) {
        list.push(user)
      }
      return list
    }, [])
  }
}
