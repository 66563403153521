import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-chevron-down-outline',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.7416 6.36978C21.5435 6.37446 21.3553 6.45731 21.2181 6.60025L11.9984 15.8199L2.7787 6.60025C2.70879 6.52826 2.62515 6.47103 2.53272 6.43194C2.44029 6.39286 2.34096 6.37272 2.24061 6.37271C2.09135 6.37275 1.9455 6.41732 1.82171 6.50071C1.69792 6.58411 1.60182 6.70254 1.54572 6.84086C1.48962 6.97918 1.47606 7.13108 1.50678 7.27715C1.53749 7.42322 1.61109 7.5568 1.71815 7.6608L11.4681 17.4108C11.6088 17.5514 11.7995 17.6304 11.9984 17.6304C12.1973 17.6304 12.388 17.5514 12.5287 17.4108L22.2787 7.6608C22.3878 7.55612 22.4626 7.42093 22.4935 7.27295C22.5244 7.12497 22.5099 6.97111 22.4518 6.83153C22.3938 6.69196 22.2949 6.57319 22.1681 6.49079C22.0414 6.40839 21.8927 6.36621 21.7416 6.36978Z"
    />
  </svg>`,
})
export class IconChevronDownOutlineComponent {}
