import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-close-filled',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_932_20573)">
      <path
        d="M19.4912 3.48536C19.227 3.49159 18.9759 3.60219 18.793 3.79297L12 10.5859L5.20706 3.79297C5.11375 3.69706 5.00215 3.62085 4.87886 3.56885C4.75557 3.51685 4.62309 3.49012 4.48928 3.49024C4.29043 3.49049 4.09617 3.55 3.93131 3.66119C3.76645 3.77238 3.63848 3.93019 3.56374 4.11446C3.48901 4.29873 3.4709 4.5011 3.51174 4.69571C3.55257 4.89032 3.65049 5.06835 3.79299 5.20704L10.586 12L3.79299 18.793C3.69702 18.8851 3.6204 18.9955 3.56761 19.1176C3.51483 19.2397 3.48694 19.3712 3.48559 19.5042C3.48423 19.6373 3.50944 19.7692 3.55973 19.8924C3.61002 20.0156 3.68438 20.1275 3.77846 20.2216C3.87254 20.3157 3.98444 20.39 4.10762 20.4403C4.2308 20.4906 4.36277 20.5158 4.49581 20.5144C4.62885 20.5131 4.76029 20.4852 4.88241 20.4324C5.00454 20.3796 5.11491 20.303 5.20706 20.207L12 13.4141L18.793 20.207C18.8851 20.303 18.9955 20.3796 19.1176 20.4324C19.2398 20.4852 19.3712 20.5131 19.5042 20.5144C19.6373 20.5158 19.7693 20.4906 19.8924 20.4403C20.0156 20.39 20.1275 20.3157 20.2216 20.2216C20.3157 20.1275 20.39 20.0156 20.4403 19.8924C20.4906 19.7692 20.5158 19.6373 20.5145 19.5042C20.5131 19.3712 20.4852 19.2397 20.4324 19.1176C20.3797 18.9955 20.303 18.8851 20.2071 18.793L13.4141 12L20.2071 5.20704C20.3526 5.0675 20.4525 4.88725 20.4938 4.68992C20.5351 4.49258 20.5157 4.28738 20.4383 4.10123C20.3609 3.91508 20.2291 3.75666 20.0601 3.64675C19.8911 3.53685 19.6928 3.48059 19.4912 3.48536Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_932_20573"><rect width="24" height="24" /></clipPath>
    </defs>
  </svg>`,
})
export class IconCloseFilledComponent {}
