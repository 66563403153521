import { Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BuilderFacade } from '../+state/builder.facade'
import { QuestionnaireItem } from '../models/fhir-interfaces'
import { QuestionComponent } from '../question/question.component'

@Component({
  selector: 'app-editor-renderer',
  standalone: true,
  imports: [CommonModule, QuestionComponent],
  templateUrl: './editor-renderer.component.html',
  styleUrl: './editor-renderer.component.scss',
})
export class EditorRendererComponent {
  @Input() questions: QuestionnaireItem[] = []
  @Input() title = ''
  private readonly builderFacade = inject(BuilderFacade)

  handleEditBtn(event: Event, question: QuestionnaireItem) {
    event.stopPropagation()
    this.builderFacade.editQuestion(question)
  }
}
