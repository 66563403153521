import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { IconCloseFilledComponent } from '@client-workspace/icons/close_filled.component'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { MatButtonModule } from '@angular/material/button'

@Component({
  selector: 'app-user-deletion-form',
  standalone: true,
  imports: [
    MatDialogClose,
    MatDialogModule,
    MatButtonModule,
    IconCloseFilledComponent,
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
  ],
  templateUrl: './user-deletion-dialog.component.html',
  styleUrl: './user-deletion-dialog.component.scss',
})
export class UserDeletionComponent {
  public requestSent = false
  public apiReturnedError = false
  public userEmail = ''

  constructor(
    private dialog: MatDialogRef<UserDeletionComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: string
      firstName?: string
      lastName?: string
      email: string
    },
  ) {
    this.userEmail = this.data.email
  }

  async deleteUser() {
    this.requestSent = true
    this.dialog.close(this.data)
    /*
    const { error, response } = await this.userService.deleteUser(this.data.id)

    this.requestSent = false

    if (response.status === 204) {
      this.dialog.close(response.status)
      this.toastsService.add({
        type: 'success',
        msg: this.translation.instant('userManagement.userDeletionDialog.userDeletedSuccessMessage', { userMail: this.data.email }),
      })
      return true
    }

    if (error) {
      this.toastsService.add({
        type: 'error',
        msg: this.translation.instant('userManagement.editUserForm.userUpdatedFailure'),
      })
    }
    return false*/
  }
}
