import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Pipe({
  name: 'translateJoin',
  pure: false,
  standalone: true,
})
export class TranslateJoinPipe implements PipeTransform {
  constructor(private _translateService: TranslateService) {}
  transform(values: string[]): string {
    if (!values) return ''
    const translations = values.map((key) => this._translateService.instant(key))
    return translations.join(', ')
  }
}
