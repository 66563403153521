import { Injectable, inject } from '@angular/core'
import { createEffect, Actions, ofType } from '@ngrx/effects'
import { switchMap, catchError, of } from 'rxjs'
import * as WorkflowEngineActions from './workflow-engine.actions'
import * as AppActions from '../../../+state/app.actions'
import { Router } from '@angular/router'

@Injectable()
export class WorkflowEngineEffects {
  private actions$ = inject(Actions)
  private router = inject(Router)

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowEngineActions.initWorkflowEngine),
      switchMap(() => of(WorkflowEngineActions.loadWorkflowEngineSuccess({ workflowEngine: [] }))),
      catchError((error) => {
        console.error('Error', error)
        return of(WorkflowEngineActions.loadWorkflowEngineFailure({ error }))
      }),
    ),
  )

  resetState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[WorkflowEngineActions.saveWorkflow]),
      switchMap(() => {
        const redirectPage = '/workflow-engine/list'
        this.router.navigate([redirectPage])
        return of(AppActions.userRedirected({ url: redirectPage }))
      }),
    ),
  )
}
