import { Injectable, inject } from '@angular/core'
import { select, Store, Action } from '@ngrx/store'

import * as WorkflowEngineActions from './workflow-engine.actions'
import * as WorkflowEngineSelectors from './workflow-engine.selectors'
import { Workflow } from '../../../questionnaire-builder/models/fhir-interfaces'

@Injectable()
export class WorkflowEngineFacade {
  private readonly store = inject(Store)

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(WorkflowEngineSelectors.selectWorkflowEngineLoaded))
  allWorkflowEngine$ = this.store.pipe(select(WorkflowEngineSelectors.selectAllWorkflowEngine))
  selectedWorkflowEngine$ = this.store.pipe(select(WorkflowEngineSelectors.selectEntity))

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(WorkflowEngineActions.initWorkflowEngine())
  }

  loadQuestionnaires() {
    this.store.dispatch(WorkflowEngineActions.loadQuestionnaires())
  }

  saveWorkflow(workflow: Workflow) {
    this.store.dispatch(WorkflowEngineActions.saveWorkflow({ workflow }))
  }
}
