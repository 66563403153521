import { Component } from '@angular/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { Router, RouterLink } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'

export interface ErrorComponentDataParams {
  button: { link: string; label: string };
  error: { message: string; statusCode: number }
}

export const DEFAULT_404: ErrorComponentDataParams = {
  error: {
    statusCode: 404,
    message: 'error.description404'
  },
  button: {
    link: '/home',
    label: 'error.returnButtonLabel'
  }
}

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink, NgOptimizedImage],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss'
})
export class ErrorComponent {
  data: ErrorComponentDataParams = DEFAULT_404

  constructor(private router: Router) {
    const state = this.router.getCurrentNavigation()?.extras.state as { data: ErrorComponentDataParams }
    this.data = state?.data ? state.data : DEFAULT_404
  }
}
