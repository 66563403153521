import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-plus-filled',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_940_20521)">
      <path
        d="M11.9854 2.48633C11.7203 2.49021 11.4677 2.59916 11.2829 2.78923C11.0981 2.9793 10.9964 3.23495 11 3.5V11H3.50002C3.36751 10.9981 3.23595 11.0226 3.11298 11.072C2.99001 11.1214 2.87809 11.1948 2.78372 11.2878C2.68935 11.3809 2.61441 11.4918 2.56327 11.614C2.51212 11.7363 2.48578 11.8675 2.48578 12C2.48578 12.1325 2.51212 12.2637 2.56327 12.386C2.61441 12.5082 2.68935 12.6191 2.78372 12.7122C2.87809 12.8052 2.99001 12.8786 3.11298 12.928C3.23595 12.9774 3.36751 13.0019 3.50002 13H11V20.5C10.9981 20.6325 11.0226 20.7641 11.072 20.887C11.1215 21.01 11.1948 21.1219 11.2879 21.2163C11.3809 21.3107 11.4918 21.3856 11.614 21.4368C11.7363 21.4879 11.8675 21.5142 12 21.5142C12.1325 21.5142 12.2638 21.4879 12.386 21.4368C12.5083 21.3856 12.6191 21.3107 12.7122 21.2163C12.8052 21.1219 12.8786 21.01 12.928 20.887C12.9774 20.7641 13.0019 20.6325 13 20.5V13H20.5C20.6325 13.0019 20.7641 12.9774 20.8871 12.928C21.01 12.8786 21.122 12.8052 21.2163 12.7122C21.3107 12.6191 21.3856 12.5082 21.4368 12.386C21.4879 12.2637 21.5143 12.1325 21.5143 12C21.5143 11.8675 21.4879 11.7363 21.4368 11.614C21.3856 11.4918 21.3107 11.3809 21.2163 11.2878C21.122 11.1948 21.01 11.1214 20.8871 11.072C20.7641 11.0226 20.6325 10.9981 20.5 11H13V3.5C13.0019 3.36629 12.9768 3.23358 12.9265 3.1097C12.8761 2.98583 12.8014 2.87331 12.7069 2.7788C12.6123 2.6843 12.4997 2.60972 12.3757 2.55948C12.2518 2.50925 12.1191 2.48437 11.9854 2.48633Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_940_20521"><rect width="24" height="24" /></clipPath>
    </defs>
  </svg>`,
})
export class IconPlusFilledComponent {}
