import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-clock-alert',
  standalone: true,
  template: `<svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="clock_alert">
      <path
        id="Vector"
        d="M27.3889 4.66675C15.1356 4.66675 5.16669 14.6356 5.16669 26.8889C5.16669 37.6356 12.8356 46.6228 22.9878 48.6706C22.8412 47.4617 23.091 46.2164 23.7365 45.142L33.7365 28.4753C34.7332 26.812 36.5589 25.7778 38.5 25.7778C40.4411 25.7778 42.2657 26.813 43.2634 28.4775L47.6992 35.869C48.9203 33.119 49.6111 30.0856 49.6111 26.8889C49.6111 14.6356 39.6422 4.66675 27.3889 4.66675ZM27.9444 13.5556C28.8644 13.5556 29.6111 14.3023 29.6111 15.2223V28.5556C29.6111 29.4756 28.8644 30.2223 27.9444 30.2223H19.0556C18.1356 30.2223 17.3889 29.4756 17.3889 28.5556C17.3889 27.6356 18.1356 26.8889 19.0556 26.8889H26.2778V15.2223C26.2778 14.3023 27.0244 13.5556 27.9444 13.5556ZM38.5 28.0001C37.3289 28.0001 36.2441 28.6145 35.6419 29.619L25.6419 46.2856C25.0241 47.3156 25.0074 48.5984 25.5985 49.6428C26.1907 50.6873 27.2989 51.3334 28.5 51.3334H48.5C49.7011 51.3334 50.8092 50.6873 51.4014 49.6428C51.9925 48.5984 51.9758 47.3145 51.358 46.2856L41.358 29.619C40.7558 28.6145 39.6711 28.0001 38.5 28.0001ZM38.5 32.4445C39.1133 32.4445 39.6111 32.9423 39.6111 33.5556V42.4445C39.6111 43.0578 39.1133 43.5556 38.5 43.5556C37.8866 43.5556 37.3889 43.0578 37.3889 42.4445V33.5556C37.3889 32.9423 37.8866 32.4445 38.5 32.4445ZM38.5 45.5C39.2666 45.5 39.8889 46.1223 39.8889 46.8889C39.8889 47.6556 39.2666 48.2778 38.5 48.2778C37.7333 48.2778 37.1111 47.6556 37.1111 46.8889C37.1111 46.1223 37.7333 45.5 38.5 45.5Z"
        fill="#A16207"
      />
    </g>
  </svg> `,
})
export class IconClockAlertComponent {}
