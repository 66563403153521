@if (title) {
  <h1 class="questionnaire-title heading-s">{{ title }}</h1>
}
@for (item of questions; track item.linkId) {
  <div
    [ngClass]="{ 'group-container': item.type === 'group', 'canvas-item': item.type !== 'group' }"
    [id]="item.linkId"
  >
    @if (item.type === 'group') {
      <div class="group" [class]="'layout-' + item.layout" [id]="item.linkId" (click)="handleEditBtn($event, item)">
        <app-editor-renderer [questions]="item.items || []"></app-editor-renderer>
      </div>
    } @else {
      <app-question class="is-builder" [question]="item" (click)="handleEditBtn($event, item)"></app-question>
    }
  </div>
}
