<div class="card centered">
  <h1 class="heading-m m-semibold m-centered">{{ 'home.titleWelcome' | translate }}</h1>
  @if (authFacade.hasPermissionCrudUser$ | async) {
    <p class="text-l">{{ 'home.WelcomeMessage1' | translate }}</p>

    <button [routerLink]="['/user-management']" class="btn-primary m-centered">{{ 'home.WelcomeButton' | translate }}</button>
  } @else {
    <p class="text-l">
      {{ 'home.WelcomeMessage2' | translate }}
    </p>
  }
</div>
