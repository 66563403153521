import { type Routes } from '@angular/router'
import { WorkflowEngineComponent } from './workflow-engine.component'
import { provideState } from '@ngrx/store'
import { provideEffects } from '@ngrx/effects'
import * as fromWorkflowEngine from './+state/workflow-engine.reducer'
import { WorkflowEngineEffects } from './+state/workflow-engine.effects'
import { WorkflowEngineFacade } from './+state/workflow-engine.facade'
import { WorkflowListComponent } from './workflow-list/workflow-list.component'
import { WorkflowRendererComponent } from './workflow-renderer/workflow-renderer.component'
export const workflowEngineRoutes: Routes = [
  {
    path: 'workflow-engine',
    children: [
      {
        path: '',
        redirectTo: '/workflow-engine/list',
        pathMatch: 'full',
        title: 'CaaS - Workflow Editor',
      },
      {
        path: 'new',
        component: WorkflowEngineComponent,
        title: 'CaaS - Workflow Engine',
      },
      {
        path: 'list',
        component: WorkflowListComponent,
        title: 'CaaS - Workflows list',
      },
      {
        path: ':id',
        component: WorkflowRendererComponent,
        title: 'CaaS - Questionnaire Renderer',
      },
    ],
    providers: [
      WorkflowEngineFacade,
      provideState(fromWorkflowEngine.WORKFLOW_ENGINE_FEATURE_KEY, fromWorkflowEngine.workflowEngineReducer),
      provideEffects(WorkflowEngineEffects),
    ],
  },
]
