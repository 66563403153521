import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-edit-filled',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.3285 9.26799L20.5485 8.048C21.8155 6.781 21.8155 4.719 20.5485 3.4515C19.935 2.8385 19.1195 2.5015 18.25 2.5015C17.3805 2.5015 16.5645 2.839 15.9515 3.452L14.732 4.6715L19.3285 9.26799ZM13.6715 5.732L4.63699 14.7665C4.44449 14.959 4.29799 15.1965 4.21299 15.454L2.53799 20.5145C2.44849 20.7835 2.51899 21.08 2.71949 21.2805C2.86299 21.4235 3.05399 21.5 3.24999 21.5C3.32899 21.5 3.40849 21.4875 3.48599 21.462L8.54499 19.7865C8.80349 19.7015 9.04149 19.555 9.23399 19.362L18.268 10.328L13.6715 5.732Z"
    />
  </svg>`,
})
export class IconEditFilledComponent {}
