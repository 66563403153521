import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { QuestionComponent } from '../../../questionnaire-builder/question/question.component'
import { QuestionnaireItem } from '../../../questionnaire-builder/models/fhir-interfaces'

@Component({
  selector: 'app-questionnaire-renderer',
  standalone: true,
  imports: [CommonModule, QuestionComponent],
  templateUrl: './questionnaire-renderer.component.html',
  styleUrl: './questionnaire-renderer.component.scss',
})
export class QuestionnaireRendererComponent {
  @Input() questions: QuestionnaireItem[] = []
  @Input() title = ''
}
