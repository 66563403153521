import { inject, Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'

import * as UserManagementActions from './user-management.actions'
import * as UserManagementSelectors from './user-management.selectors'
import { Sort } from '@angular/material/sort'
import { User } from '../../../generated'

@Injectable()
export class UserManagementFacade {
  private readonly store = inject(Store)

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(UserManagementSelectors.selectUserManagementLoaded))
  error$ = this.store.pipe(select(UserManagementSelectors.selectUserManagementError))
  allUsers$ = this.store.pipe(select(UserManagementSelectors.selectAllUserManagement))
  users$ = this.store.pipe(select(UserManagementSelectors.selectFilteredAndSortedUsers))
  selectedUserManagement$ = this.store.pipe(select(UserManagementSelectors.selectEntity))

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(UserManagementActions.initUserManagement())
  }

  sort(sort: Sort) {
    this.store.dispatch(UserManagementActions.sortUsers({ sort }))
  }

  filter(term: string) {
    this.store.dispatch(UserManagementActions.filterUsers({ filter: term }))
  }

  update(user: User) {
    this.store.dispatch(UserManagementActions.updateUser({ user }))
  }

  create(user: User) {
    this.store.dispatch(UserManagementActions.createUser({ user }))
  }

  delete(user: User) {
    this.store.dispatch(UserManagementActions.deleteUser({ user }))
  }

  toggleEnabled(user: User) {
    this.store.dispatch(UserManagementActions.patchEnabledStatus({ user }))
  }
}
