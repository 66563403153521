import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-circle-error-filled',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_818_20807)">
      <path
        d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM11.25 7.25C11.25 6.8355 11.5855 6.5 12 6.5C12.4145 6.5 12.75 6.8355 12.75 7.25V13.25C12.75 13.6645 12.4145 14 12 14C11.5855 14 11.25 13.6645 11.25 13.25V7.25ZM12 17.5C11.4475 17.5 11 17.0525 11 16.5C11 15.9475 11.4475 15.5 12 15.5C12.5525 15.5 13 15.9475 13 16.5C13 17.0525 12.5525 17.5 12 17.5Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_818_20807"><rect width="24" height="24" /></clipPath>
    </defs>
  </svg>`,
})
export class IconCircleErrorFilledComponent {}
