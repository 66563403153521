import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { CommonModule } from '@angular/common'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { MatButtonModule } from '@angular/material/button'
import { IconCloseFilledComponent } from '@client-workspace/icons/close_filled.component'
import { ToastsService } from '@client-workspace/toasts'

@Component({
  selector: 'app-user-activation-form',
  standalone: true,
  imports: [
    MatDialogClose,
    MatDialogModule,
    MatButtonModule,
    IconCloseFilledComponent,
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
  ],
  templateUrl: './user-activation-dialog.component.html',
  styleUrl: './user-activation-dialog.component.scss',
})
export class UserActivationComponent {
  public requestSent = false
  public apiReturnedError = false
  public userEmail = ''

  constructor(
    private translation: TranslateService,
    private toastsService: ToastsService,
    private dialog: MatDialogRef<UserActivationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: string
      firstName?: string
      lastName?: string
      email: string
      enabled: boolean
    },
  ) {
    this.userEmail = data.email
  }

  async updateUser() {
    this.dialog.close(this.data)
  }
}
