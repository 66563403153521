import { Component, inject } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { MatDialog } from '@angular/material/dialog'
import { RouterLink } from '@angular/router'
import { AsyncPipe } from '@angular/common'
import { AuthFacade } from '../auth/+state/auth.facade'

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [TranslateModule, RouterLink, AsyncPipe],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  authFacade = inject(AuthFacade)

  constructor(public dialog: MatDialog) {}
}
