import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { createReducer, on, Action } from '@ngrx/store'

import * as BuilderActions from './builder.actions'
import { BuilderEntity } from './builder.models'
import { QuestionnaireItem } from '../models/fhir-interfaces'
import { deleteDeeplyNestedObj, replaceDeeplyNestedObj } from '../../-shared/utils/utils'

export const BUILDER_FEATURE_KEY = 'builder'

export interface BuilderState extends EntityState<BuilderEntity> {
  loaded: boolean // has the Builder list been loaded
  questions: QuestionnaireItem[]
  error?: string | null // last known error (if any)
  editQuestion?: QuestionnaireItem
  id?: string
  name: string
  title: string
}

export interface BuilderPartialState {
  readonly [BUILDER_FEATURE_KEY]: BuilderState
}

export const builderAdapter: EntityAdapter<BuilderEntity> = createEntityAdapter<BuilderEntity>()

export const initialBuilderState: BuilderState = builderAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  questions: [],
  name: '',
  title: '',
})

const reducer = createReducer(
  initialBuilderState,
  on(BuilderActions.initBuilder, (state) => ({ ...state, loaded: false, error: null })),
  on(BuilderActions.resetState, (state) => {
    return { ...state, questions: [], name: '', title: '' }
  }),
  on(BuilderActions.loadBuilderSuccess, (state, { builder }) =>
    builderAdapter.setAll(builder, { ...state, loaded: true }),
  ),
  on(BuilderActions.loadBuilderFailure, (state, { error }) => ({ ...state, error })),
  on(BuilderActions.setQuestions, (state, { questions }) => ({ ...state, questions })),
  on(BuilderActions.addQuestion, (state, { question }) => ({ ...state, questions: [...state.questions, question] })),
  on(BuilderActions.editQuestion, (state, { question }) => ({ ...state, editQuestion: question })),
  on(BuilderActions.updateQuestion, (state, { question }) => {
    const questions = structuredClone(state.questions)
    replaceDeeplyNestedObj(questions, question, 'linkId')
    return { ...state, questions, editQuestion: undefined }
  }),
  on(BuilderActions.deleteQuestion, (state, { question }) => {
    const questions = structuredClone(state.questions)
    deleteDeeplyNestedObj(questions, question, 'linkId')
    return { ...state, questions, editQuestion: undefined }
  }),
  on(BuilderActions.updateProperties, (state, { name, title }) => {
    return { ...state, name, title }
  }),

  on(BuilderActions.saveQuestionnaire, (state) => {
    fetch('http://localhost:8080/services/crud-playground/questionnaires', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        language: 'de-DE',
        resourceType: 'Questionnaire',
        status: 'draft',
        name: state.name,
        title: state.title,
        item: state.questions,
      }),
    })
    return state
  }),

  on(BuilderActions.updateQuestionnaire, (state) => {
    fetch(`http://localhost:8080/services/crud-playground/questionnaires/${state.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        language: 'de-DE',
        resourceType: 'Questionnaire',
        status: 'draft',
        name: state.name,
        title: state.title,
        item: state.questions,
      }),
    })

    return state
  }),

  on(BuilderActions.loadQuestionnaire, (state, { id, questionnaire }) => {
    return { ...state, id, title: questionnaire.title, name: questionnaire.name, questions: questionnaire.item }
  }),
)

export function builderReducer(state: BuilderState | undefined, action: Action) {
  return reducer(state, action)
}
