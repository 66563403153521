import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-circle-close-filled',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_991_20636)">
      <path
        d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM15.7805 14.7195C16.0735 15.0125 16.0735 15.487 15.7805 15.78C15.634 15.927 15.442 16 15.25 16C15.058 16 14.866 15.927 14.7195 15.7805L12 13.0605L9.2805 15.78C9.134 15.927 8.942 16 8.75 16C8.558 16 8.366 15.927 8.2195 15.7805C7.9265 15.4875 7.9265 15.013 8.2195 14.72L10.9395 12L8.22 9.2805C7.927 8.9875 7.927 8.513 8.22 8.22C8.513 7.927 8.9875 7.927 9.2805 8.22L12 10.9395L14.7195 8.22C15.0125 7.927 15.487 7.927 15.78 8.22C16.073 8.513 16.073 8.9875 15.78 9.2805L13.0605 12L15.7805 14.7195Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_991_20636"><rect width="24" height="24" /></clipPath>
    </defs>
  </svg>`,
})
export class IconCircleCloseFilledComponent {}
