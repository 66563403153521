export function highlightText(searchingStr: string, rootEl: HTMLElement) {
  if (!searchingStr || !rootEl) {
    // @ts-expect-error: "clear" method exists on CSS.highlights
    CSS.highlights.clear()
    return
  }
  // First get all text nodes in the table.
  const treeWalker = document.createTreeWalker(rootEl, NodeFilter.SHOW_TEXT)
  const allTextNodes: Node[] = []
  let currentNode = treeWalker.nextNode()
  while (currentNode) {
    const parent = currentNode.parentNode

    if (
      currentNode instanceof Node &&
      parent instanceof Element &&
      getComputedStyle(parent).display !== 'none' &&
      parent.getAttribute('data-test') !== 'no-users-message' // don't consider "keine ergebniese" message
    ) {
      allTextNodes.push(currentNode)
    }
    currentNode = treeWalker.nextNode()
  }

  // Then, loop through them, every time splitting them into
  // individual words, and creating a list of words per node.
  const allWords: { parentNode: Node; offset: number }[] = []
  for (const textNode of allTextNodes) {
    const text = textNode.textContent ?? ''
    for (const word of text.matchAll(new RegExp(searchingStr, 'ig'))) {
      allWords.push({
        parentNode: textNode,
        offset: word.index,
      })
    }
  }

  // Finally, loop through the words and highlight them one by
  // one by creating a Range and add them to Highlight object.
  const ranges: Range[] = []
  for (const { parentNode, offset } of allWords) {
    const range = new Range()
    range.setStart(parentNode, offset)
    range.setEnd(parentNode, offset + searchingStr.length)
    ranges.push(range)
  }

  const highlight = new Highlight(...ranges)
  // @ts-expect-error: "set" method exists on CSS.highlights
  CSS.highlights.set('search-highlight', highlight)
}
