import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-eye-show-outline',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9932 4.49995C6.33341 4.49995 1.34655 8.42287 0.0235621 13.5633C-0.0259065 13.756 0.00319855 13.9605 0.104474 14.1317C0.20575 14.303 0.370901 14.4269 0.563596 14.4764C0.75629 14.5259 0.960743 14.4968 1.13198 14.3955C1.30321 14.2942 1.4272 14.1291 1.47667 13.9364C2.61666 9.50694 7.03303 5.99994 11.9932 5.99994C16.9533 5.99994 21.3837 9.50818 22.5233 13.9364C22.5728 14.1291 22.6968 14.2942 22.868 14.3955C23.0393 14.4968 23.2437 14.5259 23.4364 14.4764C23.6291 14.4269 23.7943 14.303 23.8955 14.1317C23.9968 13.9605 24.0259 13.756 23.9764 13.5633C22.6531 8.42164 17.6529 4.49995 11.9932 4.49995ZM12.001 8.49991C9.34097 8.49991 7.169 10.6719 7.169 13.3319C7.169 15.9919 9.34097 18.1649 12.001 18.1649C14.661 18.1649 16.8339 15.9919 16.8339 13.3319C16.8339 10.6719 14.661 8.49991 12.001 8.49991ZM12.001 9.9999C13.8503 9.9999 15.334 11.4825 15.334 13.3319C15.334 15.1813 13.8503 16.6649 12.001 16.6649C10.1516 16.6649 8.66898 15.1813 8.66898 13.3319C8.66898 11.4825 10.1516 9.9999 12.001 9.9999Z"
    />
  </svg>`,
})
export class IconEyeShowOutlineComponent {}
