<header>
  <div>
    <a [routerLink]="['/']" class="logo">
      <img src="../assets/logo.svg" alt="logo" />
      <h1>{{ title }}</h1>
    </a>
  </div>

  @if (isDesktopScreen) {
    <menu class="desktop-items">
      @if (user$ | async) {
        <span data-testid="user">{{ (user$ | async)?.firstName }} {{ (user$ | async)?.lastName }}</span>

        <button data-testId="logout" class="btn-primary small" (click)="handleLogout()">
          {{ 'auth.logout.buttonLabel' | translate }}
        </button>
      } @else {
        <button class="btn-primary small" data-testId="login" [routerLink]="['/login']" routerLinkActive="is-current">
          {{ 'auth.login.buttonLabel' | translate }}
        </button>
      }

      <cc-select-field
        id="language-select"
        style="width: 130px"
        [(selected)]="selectedLanguage"
        (selectedChange)="onLanguageChange()"
        [options]="[
          { label: 'Deutsch', value: 'de' },
          { label: 'English', value: 'en' },
        ]"
      >
      </cc-select-field>
    </menu>
  } @else {
    <div class="mobile-items">
      <menu [class.is-visible]="isMobileMenuVisible" class="mobile-menu app-menu">
        @if (user$ | async) {
          <a
            [routerLink]="['/']"
            routerLinkActive="is-current"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="toggleMenu()"
          >
            <app-icon-home-filled class="icon"></app-icon-home-filled>
            {{ 'Home' | translate }}
          </a>

          <a
            [routerLink]="['/user-management']"
            routerLinkActive="is-current"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="toggleMenu()"
          >
            <app-icon-settings-filled class="icon"></app-icon-settings-filled>
            {{ 'User management' | translate }}
          </a>
        }

        <div class="flex-end">
          <cc-select-field
            id="language-select"
            [(selected)]="selectedLanguage"
            (selectedChange)="onLanguageChange()"
            [options]="[
              { label: 'Deutsch', value: 'de' },
              { label: 'English', value: 'en' },
            ]"
          >
          </cc-select-field>

          @if (user$ | async) {
            <span data-testid="user">{{ (user$ | async)?.firstName }} {{ (user$ | async)?.lastName }}</span>

            <button data-testId="logout" class="btn-primary small" (click)="handleLogout()">
              {{ 'Log out' | translate }}
            </button>
          } @else {
            <button
              class="btn-primary small"
              data-testId="login"
              [routerLink]="['/login']"
              routerLinkActive="is-current"
              (click)="toggleMenu()"
            >
              {{ 'Log in' | translate }}
            </button>
          }
        </div>
      </menu>

      <div class="burger" (click)="toggleMenu()">
        @if (isMobileMenuVisible) {
          <app-icon-close-outline></app-icon-close-outline>
        } @else {
          <app-icon-menu-hamburger-outline></app-icon-menu-hamburger-outline>
        }
      </div>
    </div>
  }
</header>
