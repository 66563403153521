import { createFeatureSelector, createSelector } from '@ngrx/store'
import { WORKFLOW_ENGINE_FEATURE_KEY, WorkflowEngineState, workflowEngineAdapter } from './workflow-engine.reducer'

// Lookup the 'WorkflowEngine' feature state managed by NgRx
export const selectWorkflowEngineState = createFeatureSelector<WorkflowEngineState>(WORKFLOW_ENGINE_FEATURE_KEY)

const { selectAll, selectEntities } = workflowEngineAdapter.getSelectors()

export const selectWorkflowEngineLoaded = createSelector(
  selectWorkflowEngineState,
  (state: WorkflowEngineState) => state.loaded,
)

export const selectWorkflowEngineError = createSelector(
  selectWorkflowEngineState,
  (state: WorkflowEngineState) => state.error,
)

export const selectAllWorkflowEngine = createSelector(selectWorkflowEngineState, (state: WorkflowEngineState) =>
  selectAll(state),
)

export const selectWorkflowEngineEntities = createSelector(selectWorkflowEngineState, (state: WorkflowEngineState) =>
  selectEntities(state),
)

export const selectSelectedId = createSelector(
  selectWorkflowEngineState,
  (state: WorkflowEngineState) => state.selectedId,
)

export const selectEntity = createSelector(selectWorkflowEngineEntities, selectSelectedId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined,
)
