import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-circle-question-filled',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_923_20556)">
      <path
        d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 18C11.4475 18 11 17.5525 11 17C11 16.4475 11.4475 16 12 16C12.5525 16 13 16.4475 13 17C13 17.5525 12.5525 18 12 18ZM13.495 12.4885C12.999 12.875 12.75 13.069 12.75 13.75C12.75 14.1645 12.4145 14.5 12 14.5C11.5855 14.5 11.25 14.1645 11.25 13.75C11.25 12.336 12.0145 11.7405 12.573 11.3055C13.111 10.886 13.5 10.583 13.5 9.5C13.5 8.673 12.827 8 12 8C11.173 8 10.5 8.673 10.5 9.5V9.75C10.5 10.1645 10.1645 10.5 9.75 10.5C9.3355 10.5 9 10.1645 9 9.75V9.5C9 7.8455 10.3455 6.5 12 6.5C13.6545 6.5 15 7.8455 15 9.5C15 11.3155 14.13 11.9935 13.495 12.4885Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_923_20556"><rect width="24" height="24" /></clipPath>
    </defs>
  </svg>`,
})
export class IconCircleQuestionFilledComponent {}
