import { createAction, props } from '@ngrx/store'
import { BuilderEntity } from './builder.models'
import { Questionnaire, QuestionnaireItem, Workflow } from '../models/fhir-interfaces'

export const initBuilder = createAction('[Builder Page] Init')

export const loadBuilderSuccess = createAction(
  '[Builder/API] Load Builder Success',
  props<{ builder: BuilderEntity[] }>(),
)

export const loadBuilderFailure = createAction('[Builder/API] Load Builder Failure', props<{ error: any }>())

export const resetState = createAction('[Builder/API] Reset State')

export const setQuestions = createAction('[Builder/API] Set Questions', props<{ questions: QuestionnaireItem[] }>())

export const addQuestion = createAction('[Builder/API] Add Question', props<{ question: QuestionnaireItem }>())

export const updateQuestion = createAction('[Builder/API] Update Question', props<{ question: QuestionnaireItem }>())

export const deleteQuestion = createAction('[Builder/API] Delete Question', props<{ question: QuestionnaireItem }>())

export const editQuestion = createAction('[Builder/API] Edit Question', props<{ question: QuestionnaireItem }>())

export const updateProperties = createAction('[Builder/API] Update Propeties', props<{ name: string; title: string }>())

export const saveQuestionnaire = createAction('[Builder/API] Save Questionnaire')

export const updateQuestionnaire = createAction('[Builder/API] Update Questionnaire')

export const loadQuestionnaire = createAction(
  '[Builder/API] Load Questionnaire',
  props<{ id: string; questionnaire: Questionnaire }>(),
)
