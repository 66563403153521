import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { QuestionnaireItem } from '../models/fhir-interfaces'
import { FormGroup, FormBuilder, ReactiveFormsModule, FormsModule } from '@angular/forms'
import { MatFormField, MatLabel } from '@angular/material/form-field'
import { MatCheckbox } from '@angular/material/checkbox'
import { MatInputModule } from '@angular/material/input'
import { BuilderFacade } from '../+state/builder.facade'
import { InputFieldComponent, ToggleButtonsComponent } from '@client-workspace/custom-components'
import { v4 as uuidv4 } from 'uuid'

@Component({
  standalone: true,
  selector: 'app-properties-editor',
  templateUrl: './properties-editor.component.html',
  styleUrls: ['./properties-editor.component.scss'],
  imports: [
    MatFormField,
    MatLabel,
    MatCheckbox,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    InputFieldComponent,
    ToggleButtonsComponent,
  ],
})
export class PropertiesEditorComponent implements OnChanges {
  selectedItem: QuestionnaireItem | undefined = undefined
  form: FormGroup = this.fb.group({})
  questionnaireName = ''
  questionnaireTitle = ''
  newOptionText = ''
  newCustomText = ''
  builderFacade = inject(BuilderFacade)
  properties = {}
  isRequired = false

  constructor(private fb: FormBuilder) {
    this.builderFacade.editQuestion$.subscribe((question) => {
      this.selectedItem = structuredClone(question)
      this.newCustomText = this.selectedItem?.text || ''
      this.isRequired = !!this.selectedItem?.required
    })
    this.builderFacade.properties$.subscribe((properties) => {
      this.properties = structuredClone(properties)
    })
    this.builderFacade.builderState$.subscribe((builderState) => {
      this.questionnaireName = builderState.name
      this.questionnaireTitle = builderState.title
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedItem'] && this.selectedItem) {
      this.form = this.fb.group({
        text: [this.selectedItem.text],
        required: [this.selectedItem.required ?? false],
      })
    }
  }

  onSubmit() {
    if (!this.selectedItem) {
      return
    }
    const item = structuredClone(this.selectedItem)
    item.text = this.newCustomText
    item.required = this.isRequired

    this.builderFacade.updateQuestion(item)
    this.resetFields()
  }

  resetFields() {
    this.newOptionText = ''
    this.newCustomText = ''
    this.isRequired = false
  }

  deleteOption(item: QuestionnaireItem) {
    this.builderFacade.deleteQuestion(item)
  }

  setGroupLayout(layout: string) {
    if (this.selectedItem) {
      this.selectedItem.layout = layout
    }

    // this.selectedItem
  }

  addChoiceOrCheckbox() {
    if (!this.selectedItem) {
      return
    }
    this.selectedItem.answerOptions = this.selectedItem.answerOptions || []
    this.selectedItem.answerOptions.push({
      id: uuidv4(),
      valueCoding: {
        display: '',
      },
    })
  }

  markPreselected(id: string) {
    this.selectedItem?.answerOptions?.forEach((el) => {
      el.initialSelected = el.id === id
    })
  }

  updateProperties() {
    this.builderFacade.updateProperties({ name: this.questionnaireName, title: this.questionnaireTitle })
  }
}
