import { Component, Input } from '@angular/core'
import { RouterLink } from '@angular/router'
import { IconCircleCheckFilledComponent } from '@client-workspace/icons/circle_check_filled.component'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'app-password-updated-screen',
  standalone: true,
  imports: [TranslateModule, RouterLink, IconCircleCheckFilledComponent],
  template: `
    <section class="card">
      <div class="password-updated-wrapper">
        <header class="m-text-center">
          <app-icon-circle-check-filled class="success-icon m-centered"></app-icon-circle-check-filled>
        <h3 class="heading-m m-semibold">{{ 'passwordUpdatedPage.title' | translate }}</h3>
        </header>
        <div>
          <p class="text-m m-bold">
            @if (initial) {
              {{ 'auth.passwordUpdated.initialPasswordSuccessMessage' | translate }}
            } @else {
              {{ 'auth.passwordUpdated.descriptionPart1' | translate }}
            }
          </p>
          <p class="text-m">{{ 'auth.passwordUpdated.descriptionPart2' | translate }}</p>
        </div>
        <button class=" btn-primary width-100" [routerLink]="['/login']">
        {{ 'auth.toLoginButtonLabel' | translate }}
        </button>
      </div>
    </section>
  `,
  styles: [
    `
      .card {
        max-width: 480px;
        margin-top: 60px;
        margin-inline: auto;
      }
      .password-updated-wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
      }

      .success-icon {
        fill: var(--color-green-400);
        zoom: 2.33;
        margin-bottom: 4px;
        display: block;
      }
    `,
  ],
})
export class PasswordUpdatedScreenComponent {
  @Input() initial? = false
}
