import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AUTH_FEATURE_KEY, authAdapter, AuthState } from './auth.reducer'

// Lookup the 'Auth' feature state managed by NgRx
export const selectAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY)

const { selectAll, selectEntities } = authAdapter.getSelectors()

export const selectAuthLoaded = createSelector(selectAuthState, (state: AuthState) => state.loaded)

export const selectAuthError = createSelector(selectAuthState, (state: AuthState) => state.error)

export const selectAllAuth = createSelector(selectAuthState, (state: AuthState) => selectAll(state))

export const selectAuthEntities = createSelector(selectAuthState, (state: AuthState) => selectEntities(state))

export const selectSelectedId = createSelector(selectAuthState, (state: AuthState) => state.selectedId)

export const selectEntity = createSelector(selectAuthEntities, selectSelectedId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined,
)

export const selectSessionMetaData = createSelector(selectAuthState, (state: AuthState) => state.sessionMetaData)

export const selectCurrentUser = createSelector(selectAuthState, (state: AuthState) => state.currentUser)

export const isPasswordRequested = createSelector(selectAuthState, (state: AuthState) => state.passwordRequested)

export const isPasswordUpdated = createSelector(selectAuthState, (state: AuthState) => state.passwordUpdated)

export const isTokenValid = createSelector(selectAuthState, (state: AuthState) => state.isTokenValid)

export const selectSession = createSelector(selectAuthState, (state: AuthState) => {
  return {
    meta: state.sessionMetaData,
    user: state.currentUser,
  }
})

export const selectHasPermission = (permission: string) =>
  createSelector(selectCurrentUser, (user) => user?.permissions?.includes(permission))
