<div [ngClass]="{ darken: requestSent }">
  <div class="inner-dialog">
    <div>
      <h2>
        {{ 'userDeletionDialog.deleteUserTitle' | translate }}
      </h2>
      <app-icon-close-filled
        style="fill: var(--text-heading-primary)"
        mat-dialog-close
        class="dialog-close-button"
      ></app-icon-close-filled>
    </div>

    <p class="top-text">
      {{ 'userManagement.deleteUserDialog.descriptionPart1' | translate }}
      <span class="m-bold">{{ userEmail }}</span>
      {{ 'userManagement.deleteUserDialog.descriptionPart2' | translate }}
    </p>

    <button class="btn-warning width-100" [disabled]="requestSent" (click)="deleteUser()">
      {{ 'userDeletionDialog.deleteUserAction' | translate }}
    </button>
  </div>
</div>

@if (requestSent) {
  <mat-spinner></mat-spinner>
}
