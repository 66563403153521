<section class="questionnaire-panel">
  <div>
    <h4>Questionnaire name:</h4>
    <cc-input-field placeholder="Name" [(value)]="questionnaireName"></cc-input-field>
  </div>
  <div>
    <h4>Title:</h4>
    <cc-input-field placeholder="Title" [(value)]="questionnaireTitle"></cc-input-field>
  </div>

  <label class="preview-toggle toggle small">Vorschau<input type="checkbox" /> </label>
  <button class="btn-secondary small" type="button" (click)="updateProperties()">Speichern</button>
</section>
@if (selectedItem) {
  <div class="properties-panel">
    <!-- <p class="text-xs">LinkId: {{ selectedItem.linkId }}</p>
    <p class="text-xs mb-15">Type: {{ selectedItem.type }}</p> -->

    @switch (selectedItem.type) {
      @case ('input') {
        <section>
          <div>
            <h3 class="text-s mb-10">Title:</h3>
            <cc-input-field [(value)]="newCustomText" placeholder="{{ selectedItem.text }}"></cc-input-field>
          </div>
        </section>
      }
      @case ('boolean') {
        <section>
          <div>
            <h3 class="text-s mb-10">Title:</h3>
            <cc-input-field [(value)]="newCustomText" placeholder="{{ selectedItem.text }}"></cc-input-field>
          </div>

          @for (option of selectedItem.answerOptions; track option.id) {
            <div>
              <input type="text" [(ngModel)]="option.valueCoding.display" />
            </div>
          }

          <button class="btn-primary x-small" type="button" (click)="addChoiceOrCheckbox()">Add Choice</button>
        </section>
      }
      @case ('choice') {
        <section>
          <div>
            <h3 class="text-s">Title:</h3>
            <cc-input-field [(value)]="newCustomText" placeholder="{{ selectedItem.text }}"></cc-input-field>
          </div>

          @for (option of selectedItem.answerOptions; track option.id) {
            <div>
              <input
                type="radio"
                [name]="selectedItem.linkId"
                (click)="markPreselected(option.id)"
                [checked]="option.initialSelected"
              />
              <input type="text" [(ngModel)]="option.valueCoding.display" />
            </div>
          }

          <button class="btn-primary x-small" type="button" (click)="addChoiceOrCheckbox()">Add Choice</button>
        </section>
      }

      @case ('string') {
        <section>
          <div>
            <h3 class="text-s">Text:</h3>
            <textarea rows="5" [(ngModel)]="newCustomText"></textarea>
          </div>
        </section>
      }
      @case ('signature') {
        <section>
          <div>
            <h3 class="text-s">Title:</h3>
            <cc-input-field [(value)]="newCustomText" placeholder="{{ selectedItem.text }}"></cc-input-field>
          </div>
        </section>
      }
      @case ('group') {
        <section>
          <p>Layout</p>
          <div class="flex-column">
            @for (layout of ['100', '30-70', '70-30', '50-50']; track layout) {
              <label>
                <input
                  name="layout"
                  type="radio"
                  (click)="setGroupLayout(layout)"
                  [checked]="selectedItem.layout === layout"
                />
                <span [class]="'layout-' + layout"> {{ layout }}</span>
              </label>
            }
          </div>
        </section>
      }
    }

    @if (['input', 'boolean', 'choice', 'date', 'signature'].includes(selectedItem.type)) {
      <div class="mt-15">
        <label>
          <span class="text-s"> Required </span>
          <input type="checkbox" name="enabled-toggle" class="toggle small" [(ngModel)]="isRequired" />
        </label>
      </div>
    }
    <button class="btn-secondary small prop-btn" (click)="onSubmit()" type="submit">Speichern</button>
    <button class="btn-secondary small prop-btn" type="button" (click)="deleteOption(selectedItem)">Löschen</button>
  </div>
}
