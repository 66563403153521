import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { QuestionnaireItemComponent } from './questionnaire-item/questionnaire-item.component'
import { MatCard, MatCardContent } from '@angular/material/card'
import { DividerComponent } from '@client-workspace/custom-components'
import { FhirQuestionnaire, FhirQuestionnaireResponse } from '../fhir-deserializer/types/fhir.type'
import { ActiveQuestionnaire } from '../fhir-deserializer/types/active-questionnaire.type'

@Component({
  selector: 'fhir-questionnaire-renderer',
  standalone: true,
  imports: [CommonModule, QuestionnaireItemComponent, MatCard, MatCardContent, DividerComponent],
  templateUrl: './questionnaire-renderer.component.html',
  styleUrl: './questionnaire-renderer.component.scss',
})
export class QuestionnaireRendererComponent implements OnInit {
  @Input() questionnaire!: FhirQuestionnaire
  @Input() questionnaireResponse?: FhirQuestionnaireResponse | undefined

  @Output() questionnaireResponseChange = new EventEmitter<FhirQuestionnaireResponse>()

  activeQuestionnaire: ActiveQuestionnaire | undefined

  ngOnInit(): void {
    if (this.questionnaire === undefined) return
    this.activeQuestionnaire = new ActiveQuestionnaire(this.questionnaire, this.questionnaireResponse)
  }

  questionnaireItemChange() {
    this.questionnaireResponseChange.emit(this.activeQuestionnaire?.extractResponse())
  }
}
